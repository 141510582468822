import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
// img
import t1 from "../assets/images/t1.png";
import t2 from "../assets/images/t2.png";
import t3 from "../assets/images/t3.png";
import t4 from "../assets/images/t4.png";

import Navbarinner from "../components/Navbarinner.js";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Hand() {
  return (
    <div>
      <Navbar />
      <div className="common_page">
        <div className="container">
          <h1 className="h2tag mb-4">
            <strong>Hand picked products</strong>
          </h1>
          <h2>
            <strong>
              <em>What makes a great winning product?</em>
            </strong>
          </h2>
          <h3>
            <strong>&middot;&nbsp;</strong>
            <strong>
              <strong>WOW Factor Products</strong>
            </strong>
          </h3>
          <p>
            AIShopping specifically features &ldquo;wow&rdquo; factor products,
            presenting something unique and engaging that grabs attention and
            stands out from the usual offerings. These products are especially
            easy to market through social media.
          </p>
          <h3>
            <strong>&middot;&nbsp;</strong>
            <strong>
              <strong>Problem Solvers</strong>
            </strong>
          </h3>
          <p>
            AIShopping&rsquo;s products solve problems, addressing specific
            needs identified through market research. Most purchases are made
            because there is a pressing need, not because the products are
            simply nice to have. The best problem solvers come with an emotional
            connection.
          </p>
          <h3>
            <strong>&middot;&nbsp;</strong>
            <strong>
              <strong>Good Profit Margins</strong>
            </strong>
          </h3>
          <p>
            Low-margin products require lots of micromanagement that only large
            companies can afford. AIShopping mainly supports small to
            medium-sized sellers. Thus, all product picks come with outstanding
            profit margins, ensuring profits remain great after shipping.
          </p>
          <h3>
            <strong>&middot;&nbsp;</strong>
            <strong>
              <strong>Thorough Research</strong>
            </strong>
          </h3>
          <p>
            AIShopping&rsquo;s research team does old-school research, working
            with partners and data providers. All numbers are validated on the
            market through external feeds. No fake &ldquo;store trackers&rdquo;
            or other made-up numbers. If, for example, a product&rsquo;s
            competition density is low, you can rely on this information.
          </p>
          <h3>
            <strong>&middot;&nbsp;</strong>
            <strong>
              <strong>Top-Quality Products</strong>
            </strong>
          </h3>
          <p>
            Not everything that looks great online ends up being great in
            one&rsquo;s hands. Only the highest-quality products make it to
            AIShopping&rsquo;s product picks, which means happy customers and
            fewer returns for you. If you choose to use AIShopping as a
            supplier, there are even further quality checks in place.
          </p>
          <h3>
            <strong>&middot;&nbsp;</strong>
            <strong>
              <strong>Latest Trends</strong>
            </strong>
          </h3>
          <p>
            AIShopping&rsquo;s product picks incorporate the latest e-commerce
            trends. Our research team is always on the move, and you can access
            data on particularly profitable products or new trends much sooner
            before this data appears anywhere else. Stay on top!
          </p>
          <p>&nbsp;</p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

// import lib
import isEmpty from "./isEmpty";

export const toFixed = (item, type = 2) => {
  try {
    if (!isEmpty(item) && !isNaN(item)) {
      item = parseFloat(item);
      return item.toFixed(type);
    }
    return "";
  } catch (err) {
    return "";
  }
};

export const currencyFormat = (item) => {
  try {
    if (!isEmpty(item) && !isNaN(item)) {
      item = item.toString();
      let splitValue = item.split(".");
      return splitValue[1]
        ? `${splitValue[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.${
            splitValue[1]
          }`
        : splitValue[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return "";
  } catch (err) {
    return "";
  }
};

export const floatNumDigit = (num) => {
  num = num.toString();
  let split = num.split(".");
  if (split.length > 1) {
    return split[1].length;
  }

  return 0;
};

export const truncateDecimals = (num, decimals) => {
  num = convert(num);
  let s = num.toString(),
    p = s.indexOf(".");
  s += (p < 0 ? ((p = 1 + s.length), ".") : "") + "0".repeat(decimals);
  return s.slice(0, p + 1 + decimals);
};

export const toFixedDown = (item, type = 2) => {
  try {
    var re = new RegExp("^-?\\d+(?:.\\d{0," + (type || -1) + "})?");
    return item.toString().match(re)[0];
  } catch (err) {
    return "";
  }
};

// export const toFixedDown = (item, type = 2) => {
//   try {
//     if (!isEmpty(item) && !isNaN(item)) {
//       item = parseFloat(item)
//       let decReg = new RegExp("(\\d+\\.\\d{" + type + "})(\\d)"),
//         m = item.toString().match(decReg);
//       return m ? parseFloat(m[1]) : item.valueOf();
//     }
//     return ''
//   } catch (err) {
//     return ''
//   }
// }

export const convert = (n) => {
  try {
    var sign = +n < 0 ? "-" : "",
      toStr = n.toString();
    if (!/e/i.test(toStr)) {
      return n;
    }
    var [lead, decimal, pow] = n
      .toString()
      .replace(/^-/, "")
      .replace(/^([0-9]+)(e.*)/, "$1.$2")
      .split(/e|\./);
    return +pow < 0
      ? sign +
          "0." +
          "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
          lead +
          decimal
      : sign +
          lead +
          (+pow >= decimal.length
            ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
            : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
  } catch (err) {
    return 0;
  }
};

import { getUserNotification } from "../../../action/user.action";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  title: "",
  isRead: "",
  description: "",
  userId: "",
  countStaus: "",
  createdAt: "",
};

export const getNotify = createAsyncThunk("notify", async () => {
  const { status, result } = await getUserNotification();
  if (status) return result;
  else return initialState;
});

const todoSlice = createSlice({
  name: "notify",
  initialState,
  reducers: {
    accountReset: (state, action) => {
      state = initialState;
      return state;
    },
  },
  extraReducers: (build) => {
    build.addCase(getNotify.fulfilled, (state, action) => {
      state = action.payload;
      return state;
    });
  },
});

export const { deleteTodo, accountReset } = todoSlice.actions;

export default todoSlice.reducer;

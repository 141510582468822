import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

//import api
import { getAssetData } from "../../action/walletAction";

//import component
import WalletDetails from "./WalletDetails";

const Wallet = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    getAssetData(dispatch);
  }, []);

  return (
    <>
      <div className="innerpages_wrapper">
        <div className="container">
          <div className="dash_wrapper">
            <WalletDetails />
          </div>
        </div>
      </div>
    </>
  );
};

export default Wallet;

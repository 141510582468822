import React, { useState, useEffect } from "react";
import ship from "../../assets/images/ship.svg";
import plus1 from "../../assets/images/plus1.svg";
import Lockvip from "../../assets/images/LockImg.png";
import { Modal, Dropdown, Nav, Tab } from "react-bootstrap";

import lock from "../../assets/images/lock.svg";

import config from "../../config/index";
import { setMyDraft, storeBuy } from "../../action/user.action";
import { getUserDraft } from "../../redux/features/draft/mydraftslice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { toastAlert } from "../../utils/toast";
import { useTranslation } from "react-i18next";
import { getUserStore } from "../../redux/features/userstore/userStoreSlice";
import { getUserProfile } from "../../redux/features/user/userslice";
import { isEmpty } from "../../helper/validation";
import { getUserWallet } from "../../redux/features/wallet/walletslice";

function MarketplaceList(props) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const dispatch = useDispatch();
  var { storelist } = props;
  const [loading, setloading] = useState(undefined);
  const [toggleview, settoggleview] = useState(props.listType);
  const [show, setshow] = useState(false);
  const [storeid, setStoreId] = useState("");

  const userStore = useSelector((state) => state?.userstore);

  // console.log(userStore, "User");

  const handleopen = (id) => {
    setshow(true);
    setStoreId(id);
  };

  const handleBuy = async () => {
    try {
      let data = {
        storeid: storeid,
      };

      const { message, status } = await storeBuy(data);
      if (status) {
        dispatch(getUserStore());
        dispatch(getUserProfile());
        dispatch(getUserWallet());
        setshow(false);

        toastAlert("success", t(message));
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else {
        toastAlert("error", t(message));
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    settoggleview(props.listType);
  }, [props.listType]);

  const numberWithCommas = (number) => {
    return number.toLocaleString();
  };

  return (
    <div>
      <div className="row">
        {storelist &&
          storelist.length > 0 &&
          storelist.map((item, index) => {
            let filterStatus =
              userStore &&
              userStore.length > 0 &&
              userStore.filter((el) => el.default_storeid._id === item._id);

            console.log(filterStatus, "-filterStatus");

            return (
              <div
                className={
                  toggleview == "grid"
                    ? "col-lg-4 col-xxl-3 d-flex girdView"
                    : "col-lg-12 col-xxl-12 d-flex listView"
                }
              >
                <div className="box">
                  <div className="img_box">
                    <img
                      src={`${config.API_URL}/store/${item.logo}`}
                      class="img-fluid"
                      alt="img"
                    />
                  </div>
                  <div className="product-list-detail-box">
                    <div className="list-4">
                      <h5>
                        {t("STORE_NAME")} - {item?.Store_name}{" "}
                      </h5>
                      <h5>
                        {t("SITE_URL")} - {item?.site_url}{" "}
                      </h5>
                      <h5>
                        {t("STORE_PRICE")} - $
                        {numberWithCommas(item?.Store_price)}
                      </h5>
                    </div>
                    {(!filterStatus || isEmpty(filterStatus)) && (
                      <button
                        className="primary_btn w-100 p-2 text-center"
                        onClick={() => handleopen(item._id)}
                      >
                        {t("BUY_NOW")}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
      </div>

      <Modal show={show} size="sm" className="custom_modal">
        <Modal.Header className="mb-3">
          <div className="cursor-pointer">
            <h6>{t("ARE_YOU")}?</h6>
          </div>
        </Modal.Header>
        <Modal.Body>
          <button
            class="secondary_btn"
            style={{ backgound: "none !important" }}
            onClick={() => setshow(false)}
          >
            {" "}
            {t("CANCEL")}
          </button>
          &nbsp; &nbsp;
          <button class="primary_btn" onClick={() => handleBuy()}>
            {" "}
            {t("CONFIRM")}
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default MarketplaceList;

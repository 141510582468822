import React from "react";
import axios from "axios";

function Ipchecking() {
  const getmyip = async () => {
    try {
      var reqdata = {
        email: "test123@gmail.com",
        phone: 98765432311,
        password: "Test@123",
        confirmPassword: "Test@123",
        name: "TestTest",
      };

      let respData = await axios({
        method: "post",
        url: `https://aishoppingapi.wearedev.team/api/admin/create/VR9H53EB3NVRB7PSYAPUR37M`,
        data: reqdata,
      });

      // console.log(respData, "respData");
    } catch (error) {}
  };

  return (
    <div>
      <button onClick={getmyip}>Click</button>
    </div>
  );
}

export default Ipchecking;

import React, { useEffect, useMemo, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import DataTable from "react-data-table-component";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// img
import calender from "../../assets/images/calender.svg";
import down from "../../assets/images/down.svg";
import search_icon from "../../assets/images/search_icon.svg";
import sort_arw from "../../assets/images/sort_arw.svg";

import moment from "moment";
import { getAccountHistory } from "../../action/user.action.js";
import ScrollToTopOnMount from "../../components/ScrollToTopOnMount";

import {
  downloadCSV,
  downloadXls,
  downloadpdf,
} from "../../helper/downloadFun.js";

import { useTranslation } from "react-i18next";

export default function AccountHis() {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [drpdwn, setdrpdwn] = useState();
  const [record, setRecord] = useState({
    data: [],
    count: 0,
  });

  const [filter, setFilter] = useState({
    limit: 10,
    page: 1,
    search: "",
  });

  const { t } = useTranslation();

  // console.log(dateRange,"dateRange");

  const getWithdraws = async (reqdata) => {
    try {
      var { status, result, totalrecords } = await getAccountHistory(reqdata);
      // console.log(status, result, totalrecords, 'status, result, totalrecords');
      if (status) {
        setRecord({
          data: result,
          count: totalrecords,
        });
      }
    } catch (err) {}
  };

  const CustomInput = ({ value, onClick }) => (
    <button className="custom_input bg-transparent" onClick={onClick}>
      <img src={calender} className="img-fluid" alt="logo" />
      <span className="date">{value || t("SELECT_DATE")}</span>
      {!drpdwn && <img src={down} className="img-fluid" alt="logo" />}
      {drpdwn && <img src={down} className="img-fluid uparw" alt="logo" />}
    </button>
  );

  const numberWithCommas = (number) => {
    return number.toLocaleString();
  };

  const columns = [
    {
      name: t("DATE_TIME"),
      selector: (row) => moment(row.createdAt).format("lll"),
      sortable: true,
      width: "180px",
    },
    {
      name: t("CATEGORY"),
      selector: (row) => row.reason,
      sortable: true,
      width: "160px",
      cell: (row) => {
        let data = row.reason;

        if (row.reason == "New User") {
          data = t("NEW_USER");
        }
        if (row.reason == "Buying Store") {
          data = t("BUYING_STORE");
        }
        if (row.reason == "Buying Products") {
          data = t("BUYING_PRODUCT");
        }
        if (row.reason == "User Deposit") {
          data = t("USER_DEPOSIT");
        }
        if (row.reason == "User Withdraw Pending") {
          data = t("USER_WITHDRAW");
        }
        if (row.reason == "User Withdraw Verified") {
          data = t("USER_WITHDRAW_VERIFY");
        }
        if (row.reason == "User Withdraw Rejected") {
          data = t("USER_WITHDRAW_REJECT");
        }
        if (row.reason == "Admin Withdraw") {
          data = t("ADMIN_WITHDRAW");
        }
        if (row.reason == "Admin Deposit") {
          data = t("ADMIN_DEP");
        }
        if (row.reason == "Package Subcribtion") {
          data = t("PACKAGE_SUBSCRIBE");
        }
        if (row.reason == "Store Renewal") {
          data = t("STORE_RENEWAL");
        }
        if (row.reason == "Return Product") {
          data = t("RETURN_PRODUCT");
        }
        if (row.reason == "Coin Transfer") {
          data = t("COIN_TRANSFERS");
        }
        if (row.reason == "Profits") {
          data = t("PROFITS");
        }

        return <>{data}</>;
      },
    },
    {
      name: t("CURRENCYSYMBOL"),
      selector: (row) => row.currencySymbol,
      sortable: true,
      width: "180px",
      cell: (row) => <div className="reg">{row?.currencySymbol}</div>,
    },
    {
      name: t("BEFORE_BALANCE"),
      selector: (row) => row?.prevAmount,
      sortable: true,
      width: "160px",
      cell: (row) => (
        <div className="reg">{numberWithCommas(row?.prevAmount)}</div>
      ),
    },

    {
      name: t("AFTER_BALANCE"),
      selector: (row) => row?.currAmount,
      sortable: true,
      width: "160px",
      cell: (row) => (
        <div className="reg">{numberWithCommas(row?.currAmount)}</div>
      ),
    },
    {
      name: t("TYPE"),
      selector: (row) => row?.category,
      sortable: true,
      width: "160px",
      cell: (row) => (
        <div className="reg">
          {row?.category?.charAt(0).toUpperCase() + row?.category?.slice(1)}
        </div>
      ),
    },
    {
      name: t("AMOUNT"),
      // selector: row => "$" + (row?.amount).toFixed(2),
      sortable: true,
      cell: (row) => (
        <>
          {row?.currAmount < row?.prevAmount ? (
            <div
              style={{
                backgroundColor: "red",
                padding: "4px 23px",
                color: "#fff",
                borderRadius: "50px",
              }}
            >
              {numberWithCommas(row?.amount)}
            </div>
          ) : (
            <div
              style={{
                backgroundColor: "green",
                padding: "4px 23px",
                color: "#fff",
                borderRadius: "50px",
              }}
            >
              {numberWithCommas(row?.amount)}
            </div>
          )}
        </>
      ),
      width: "160px",
    },
    // {
    //   name: 'Status',
    //   selector: row => row.status == "Success" ?"Success":"Pending",
    //   sortable: true,
    //   // width: "150px",
    // },
    // {
    //   name: 'Quantity',
    //   selector: row => row.quantity,
    //   sortable: true,
    //   width: "110px",
    // },
  ];

  const CustomNoDataMessage = () => (
    <div className="text-center py-5 my-5">
      <p> {t("ORDERS_NOT_FOUND")}</p>
    </div>
  );

  const handlePerRowsChange = async (newPerPage, page) => {
    let filterData = { ...filter, ...{ page: page, limit: newPerPage } };
    setFilter(filterData);
  };
  const handlePageChange = (page) => {
    let filterData = { ...filter, ...{ page: page } };
    setFilter(filterData);
  };

  const handleSearch = async (event) => {
    let filterData = { ...filter, ...{ search: event.target.value } };
    setFilter(filterData);
  };

  useEffect(() => {
    handleStartChange(startDate);
  }, [startDate]);

  useEffect(() => {
    handleEndChange(endDate);
  }, [endDate]);

  const handleStartChange = (date) => {
    let newDate = new Date(date);
    newDate.setMilliseconds(0);

    let filterData = { ...filter, ...{ startdate: newDate } };
    setFilter(filterData);
  };

  const handleEndChange = (date) => {
    let newDate = new Date(date);
    newDate.setMilliseconds(0);
    let filterData = { ...filter, ...{ enddate: newDate } };
    setFilter(filterData);
  };

  useMemo(() => {
    getWithdraws(filter);
  }, [filter]);

  const downloadData = (select) => {
    try {
      if (select == 1) {
        downloadCSV(record?.data, "Order List");
      } else if (select == 2) {
        downloadXls(record?.data, "Order List");
      } else if (select == 3) {
        downloadpdf(record?.data, "Order List");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const customPaginationOptions = {
    rowsPerPageText: t("ROWS_PER_PAGE"),
  };

  return (
    <div>
      <ScrollToTopOnMount />

      {/* <Sidebar navLink={"/withdraws"}/> */}
      <div>
        {/* <Navbarinner /> */}
        <div>
          {/* <h2 className="h3tag bdr mb-3" >User Withdraw</h2> */}
          <div className="categories">
            <div className="hflx mt-3">
              <div className="flx gap-3">
                <div className="input_box">
                  <div class="input-group">
                    <input
                      type="text"
                      className="form-control bg-transparent border-end-0"
                      name="text"
                      placeholder={t("SEARCH")}
                      onChange={handleSearch}
                    />
                    <span
                      class="input-group-text cursor-pointer bg-transparent"
                      id="basic-addon2"
                    >
                      <img src={search_icon} className="img-fluid" />
                    </span>
                  </div>
                </div>
                {/* <div className="custom_date" >
                  <DatePicker
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update) => { setDateRange(update); }}
                    onCalendarOpen={() => { setdrpdwn(true); }}
                    onCalendarClose={() => { setdrpdwn(false); }}
                    customInput={<CustomInput />}
                  />
                </div> */}
              </div>
              {/* <div className="flx gap-2 flex-row" >
                <div style={{ cursor: "pointer" }} onClick={() => downloadData(3)} >
                  <img src={pdf} class="img-fluid" alt="img" />
                </div>
                <div  style={{ cursor: "pointer" }}onClick={() => downloadData(2)}>
                  <img src={xlsx} class="img-fluid" alt="img" />
                </div>
                <div  style={{ cursor: "pointer" }}onClick={() => downloadData(1)}>
                  <img src={csv} class="img-fluid" alt="img" />
                </div>
              </div> */}
            </div>
            <Scrollbars style={{ width: "100%", height: 720 }}>
              <div className="custom_table_chg">
                {/* <DataTable
                  columns={columns}
                  data={orderData}
                  selectableRows
                  noDataComponent={<CustomNoDataMessage />}
                  sortIcon={<img src={sort_arw} className="img-fluid ms-2 " alt="logo" />}
                  pagination
                /> */}

                <DataTable
                  columns={columns}
                  data={record?.data}
                  paginationTotalRows={record?.count}
                  responsive
                  sortIcon={
                    <img
                      src={sort_arw}
                      className="img-fluid ms-2 "
                      alt="logo"
                    />
                  }
                  noDataComponent={<CustomNoDataMessage />}
                  pagination
                  paginationRowsPerPageOptions={[5, 10, 15, 20]}
                  paginationServer
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  paginationComponentOptions={customPaginationOptions}
                />
              </div>
            </Scrollbars>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState, useRef } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { Modal, Dropdown } from "react-bootstrap";
// img
import all from "../assets/images/all.svg";
import toys from "../assets/images/toys.svg";
import home from "../assets/images/home.svg";
import tools from "../assets/images/tools.svg";

import outdoor from "../assets/images/outdoor.svg";
import sports from "../assets/images/sports.svg";
import pets from "../assets/images/pets.svg";
import electronics from "../assets/images/electronics.svg";
import cloth from "../assets/images/cloth.svg";
import others from "../assets/images/others.svg";
import search_icon from "../assets/images/search_icon.svg";
import filter from "../assets/images/filter.svg";
import ship from "../assets/images/ship.svg";
import plus1 from "../assets/images/plus1.svg";
import ScrollToTop from "react-scroll-to-top";

import Sidebar from "../components/sidebar.js";
import MyChart from "../components/linechart.js";
import MyLineChart from "../components/linechart.js";
import Navbarinner from "../components/Navbarinner.js";
import { Link, useLocation } from "react-router-dom";
import { getAllStores } from "../action/user.action";
import StoreList from "../components/store/list";
import { ReactComponent as MySVG } from "../assets/images/uparrow.svg";

import config from "../config/index";

import ReactPaginate from "react-paginate";
import { useTranslation } from "react-i18next";
export default function Marketplace() {
  const location = useLocation();

  // console.log(location, 'location')

  const [selectedValue, setSelectedValue] = useState(null);

  const [storelist, setstorelist] = useState([]);

  const [activecat, setactivecat] = useState(0);
  const [search, setsearch] = useState("");

  const [sort, setsort] = useState("");
  const [listType, setlistType] = useState("grid");

  const { t } = useTranslation();

  // useEffect(() => {
  //   setactivecat(location.state)
  // })

  const handleSelect = (selectedItem) => {
    // console.log(selectedItem, "selectedItem");
    setSelectedValue(selectedItem);
    if (selectedItem == "Store Price:Low - High") {
      setsort(JSON.stringify({ Store_price: 1 }));
    } else if (selectedItem == "Store Price:High - Low") {
      setsort(JSON.stringify({ Store_price: -1 }));
    } else if (selectedItem == "Recents") {
      setsort(JSON.stringify({ createdAt: -1 }));
    } else if (selectedItem == "Oldest") {
      setsort(JSON.stringify({ createdAt: 1 }));
    }
    // console.log(sort, "sort");
  };

  const trackStyles = {
    backgroundColor: "#000 !important", // specify your desired track color
    borderRadius: "5px", // optional: add border radius for a rounded look
  };

  useEffect(() => {
    getProduct();
  }, [activecat, search, selectedValue, sort]);

  const getProduct = async () => {
    try {
      // console.log("activecat", activecat)

      // var id = 0;
      // // console.log("activecat1", activecat)
      // if (activecat != 0) {
      //   id = activecat?._id
      // }

      if (sort == "") {
        setsort(JSON.stringify(" "));
      }
      if (selectedValue == "All") {
        setsort(JSON.stringify({ createdAt: -1 }));
      }

      const { result, status } = await getAllStores(sort, search);
      if (result) {
        setstorelist(result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  function showList(val) {
    setlistType(val);
  }

  // console.log("storelist", storelist)

  return (
    <div className="page_wrapper">
      <Sidebar navLink={"/store-marketplace"} />
      <div className="w-100">
        <Navbarinner />
        <div className="right_wrapper">
          <h2 className="h3tag bdr mb-3">{t("STORE_MARKETPLACE")}</h2>
          {/* <Scrollbars style={{ width: "100%", height: 135 }} trackXProps={{ style: trackStyles }}   > */}
          {/* <div className="sel_flx sel_flx_chg">

            <div onClick={() => setactivecat(0)} className={activecat == 0 ? "active" : ""}>
              <img src={all} className="img-fluid" alt="logo" />
              <span> {t("ALL_CATEGORY")}</span>
            </div>

            {catagory && catagory.length > 0 && catagory.map((item, index) => (
              <div onClick={() => setactivecat(item)} className={activecat?._id == item?._id ? "active" : ""}>
                <img src={`${config.API_URL}/catagory/${item.logo}`} className="img-fluid" alt="logo" />
                <span>{item?.CategoryName}</span>
              </div>
            ))
            }
          </div> */}
          {/* </Scrollbars> */}
          <div className="categories">
            <div className="hflx mt-3">
              {/* <h6>All Categories</h6> */}
              {/* <h6> {activecat == 0 ? t("ALL_CATEGORY") : activecat?.CategoryName}</h6> */}

              <div className="flx">
                <div className="drp_dwn ">
                  <Dropdown onSelect={handleSelect} style={{ width: "253px" }}>
                    <Dropdown.Toggle className="bg-transparent">
                      <img src={filter} className="img-fluid" />
                      <span className="mx-2">
                        {" "}
                        {selectedValue ? ` ${selectedValue}` : t("SORT_BY")}
                      </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="w-100">
                      <Dropdown.Item eventKey="All">{t("ALL")}</Dropdown.Item>
                      <Dropdown.Item eventKey="Store Price:Low - High">
                        {t("STORE_PRICE")}: {t("LOW")} - {t("HIGH")}
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="Store Price:High - Low">
                        {t("STORE_PRICE")}: {t("HIGH")} - {t("LOW")}
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="Recents">
                        {t("RECENTS")}
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="Oldest">
                        {t("OLDEST")}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                <div className="input_box">
                  <div class="input-group">
                    <span
                      class="input-group-text cursor-pointer bg-transparent"
                      id="basic-addon2"
                    >
                      <img src={search_icon} className="img-fluid" />
                    </span>
                    <input
                      type="text"
                      className="form-control bg-transparent border-end-0"
                      name="text"
                      placeholder={t("TYPE_SOME")}
                      onChange={(e) => setsearch(e.target.value)}
                    />
                    {/* <span class="input-group-text bg-transparent"><button className="primary_btn sm_txt py-1">Search</button></span> */}
                  </div>
                </div>
                <div class="btn-group listGridButton">
                  <a
                    href="#"
                    className={
                      listType == "grid"
                        ? "btn btn-primary active"
                        : "btn btn-primary"
                    }
                    onClick={() => showList("grid")}
                  >
                    <svg
                      clip-rule="evenodd"
                      fill-rule="evenodd"
                      stroke-linejoin="round"
                      stroke-miterlimit="2"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="m21 4c0-.478-.379-1-1-1h-16c-.62 0-1 .519-1 1v16c0 .621.52 1 1 1h16c.478 0 1-.379 1-1zm-12.5 15.5h-4v-4h4zm1.5-4h4v4h-4zm9.5 0v4h-4v-4zm-15-5.5h4v4h-4zm5.5 0h4v4h-4zm5.5 0h4v4h-4zm-11-5.5h4v4h-4zm5.5 0h4v4h-4zm5.5 0h4v4h-4z"
                        fill-rule="nonzero"
                      />
                    </svg>{" "}
                    {t("GALLERY")}
                  </a>
                  <a
                    href="#"
                    className={
                      listType == "list"
                        ? "btn btn-primary active"
                        : "btn btn-primary"
                    }
                    onClick={() => showList("list")}
                  >
                    <svg
                      clip-rule="evenodd"
                      fill-rule="evenodd"
                      stroke-linejoin="round"
                      stroke-miterlimit="2"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="m10.5 17.25c0-.414.336-.75.75-.75h10c.414 0 .75.336.75.75s-.336.75-.75.75h-10c-.414 0-.75-.336-.75-.75zm-1.5-3.55c0-.53-.47-1-1-1h-5c-.53 0-1 .47-1 1v4.3c0 .53.47 1 1 1h5c.53 0 1-.47 1-1zm-5.5.5h4v3.3h-4zm7-2.2c0-.414.336-.75.75-.75h10c.414 0 .75.336.75.75s-.336.75-.75.75h-10c-.414 0-.75-.336-.75-.75zm-1.5-6c0-.53-.47-1-1-1h-5c-.53 0-1 .47-1 1v4.3c0 .53.47 1 1 1h5c.53 0 1-.47 1-1zm-5.5.5h4v3.3h-4zm7 .25c0-.414.336-.75.75-.75h10c.414 0 .75.336.75.75s-.336.75-.75.75h-10c-.414 0-.75-.336-.75-.75z"
                        fill-rule="nonzero"
                      />
                    </svg>{" "}
                    {t("LIST")}
                  </a>
                </div>
              </div>
            </div>

            <StoreList storelist={storelist} listType={listType} />

            <div className="">
              <ScrollToTop
                className="Scrol_Box_div"
                smooth
                component={<MySVG />}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

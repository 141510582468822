import isEmpty from "../../lib/isEmpty";

export const DepositValidation = (value, t) => {
  let errors = {};
  let imageFormat = /\.(jpg|JPG|jpeg|JPEG|png|PNG|pdf)$/;

  if (isEmpty(value.amount)) {
    errors.amount = t("AMOUNT_REQUIRED");
  } else if (isNaN(value.amount)) {
    errors.amount = t("VALID_AMOUNT");
  } else if (parseFloat(value.minimumDeposit) > parseFloat(value.amount)) {
    errors.amount = t("DEPOSIT_AMOUNT_ERROR", { AMOUNT: value.minimumDeposit });
  }

  // Optional image validation (uncomment if needed)
  // if (value.image && value.image.size) {
  //     if (value.image.size > 1000000) {
  //         errors.image = t("IMAGE_TOO_LARGE");
  //     } else if (!imageFormat.test(value.image.name)) {
  //         errors.image = t("INVALID_IMAGE_FORMAT");
  //     }
  // } else {
  //     errors.image = t("IMAGE_REQUIRED");
  // }

  return errors;
};

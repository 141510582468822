import React, { useState, useEffect } from "react";
import ship from "../../assets/images/ship.svg";
import plus1 from "../../assets/images/plus1.svg";
import config from "../../config/index";
import { setMyDraft } from "../../action/user.action";
import { getUserDraft } from "../../redux/features/draft/mydraftslice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { toastAlert } from "../../utils/toast";

function VipMarketplaceList(props) {
  const dispatch = useDispatch();
  var { productlist } = props;
  const [loading, setloading] = useState(undefined);
  const [toggleview, settoggleview] = useState(props.listType);

  const userdraft = useSelector((state) => state?.userdraft);

  const { t } = useTranslation();

  useEffect(() => {
    settoggleview(props.listType);
  }, [props.listType]);

  const handleDraft = async (pid) => {
    try {
      // console.log(pid,);
      var reqdata = {
        productid: pid,
      };
      var { status, message } = await setMyDraft(reqdata);
      if (status) {
        setloading(pid);
        dispatch(getUserDraft());
        setTimeout(() => {
          setloading(undefined);
        }, 2000);
      } else {
        toastAlert("error", t(message));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const ButtonDisplay = (props) => {
    var { pid } = props;
    const findone = userdraft.find((data) => data.product_id == pid);
    return (
      <>
        {findone != undefined ? (
          <button
            className="primary_btn_success w-100 p-2 text-center"
            style={{ backgroundColor: "green" }}
          >
            <i class="fa fa-check" aria-hidden="true"></i>

            {t("ADDED_CART")}
          </button>
        ) : (
          <button
            className="primary_btn w-100 p-2 text-center"
            onClick={() => handleDraft(pid)}
          >
            <img src={plus1} className="img-fluid me-2 mb-1" alt="img" />
            {t("IMPORT_CART")}
          </button>
        )}
      </>
    );
  };

  const numberWithCommas = (number) => {
    return number.toLocaleString();
  };

  return (
    <div>
      <div className="row">
        {productlist &&
          productlist.map((item, index) => {
            return (
              <div
                className={
                  toggleview == "grid"
                    ? "col-lg-4 col-xxl-3 d-flex girdView"
                    : "col-lg-12 col-xxl-12 d-flex listView"
                }
              >
                <div className="box">
                  <div className="img_box">
                    <a
                      style={{ cursor: "pointer" }}
                      href={`/product-details/Vipmarketplace/${item._id}`}
                    >
                      <img
                        src={`${config.API_URL}/product/${item.product_image[0]}`}
                        class="img-fluid"
                        alt="img"
                      />
                    </a>
                  </div>
                  <div className="product-list-detail-box">
                    <div className="list-3">
                      <div className="bach">{item?.product_title}</div>
                      <p className="sm_txt">{item?.description}</p>
                    </div>
                    <div className="list-4">
                      <h5>
                        {t("PRODUCT_COST")} - $
                        {numberWithCommas(item?.product_cost)}{" "}
                      </h5>
                      <h5>
                        {t("IMPORT_COST")} - $
                        {numberWithCommas(item?.import_cost)}
                      </h5>
                      <h6
                        style={{ fontSize: "12px", color: "rgb(22 144 170)" }}
                      >
                        ({t("ONE_TIME")})
                      </h6>
                    </div>

                    <ButtonDisplay pid={item?.product_id} />
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default VipMarketplaceList;

import React, { useEffect, useState, useRef } from "react";
import DataTable from "react-data-table-component";
import { Scrollbars } from "react-custom-scrollbars-2";
import DatePicker from "react-datepicker";
import { Tab, Nav } from "react-bootstrap";
import Slider from "react-slick";
import "react-datepicker/dist/react-datepicker.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import GaugeChart from "react-gauge-chart";

// img
import csv from "../assets/images/csv.svg";
import xlsx from "../assets/images/xlsx.svg";
import pdf from "../assets/images/pdf.svg";
import plus1 from "../assets/images/plus1.svg";
import star_dark from "../assets/images/star_dark.svg";
import star from "../assets/images/star.svg";
import time from "../assets/images/time.svg";

import Sidebar from "../components/sidebar.js";
import MyChart from "../components/linechart.js";
import MyLineChart from "../components/linechart.js";
import Navbarinner from "../components/Navbarinner.js";
import { Link, useParams } from "react-router-dom";

import { getMyProductInfo } from "../action/user.action";
import moment from "moment";
import config from "../config/index";

import { setMyDraft } from "../action/user.action";
import { getUserDraft } from "../redux/features/draft/mydraftslice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { toastAlert } from "../utils/toast";
import EditSellCostPop from "../components/editsellpop/EditSellCostPop";
import DeletePop from "../components/deleteProduct/DeletePop";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";

export default function Productdetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [productInfo, setproductInfo] = useState([]);
  const [loading, setloading] = useState(false);
  const [show, setshow] = useState(false);
  const [showdelete, setshowdelete] = useState(false);
  const [activeTab, setActiveTab] = useState(1); // Initially set the first tab as active

  const userdraft = useSelector((state) => state?.userdraft);

  useEffect(() => {
    getProductInfo();
  }, [id]);

  const handleClose = () => {
    setshow(false);
  };

  const showDeletePop = () => {
    setshowdelete(true);
  };

  const getProductInfo = async () => {
    try {
      var { status, message, result } = await getMyProductInfo(id);
      if (result) {
        // console.log(result.product?.review_star, "surya");

        let reviewarr = [];
        for (let i = 1; i <= 5; i++) {
          if (result.product?.review_star >= i) {
            reviewarr.push(true);
          } else {
            reviewarr.push(false);
          }
        }
        result.reviewarr = reviewarr;
        setproductInfo(result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleClickPop = () => {
    setshow(true);
  };

  const settings = {
    customPaging: function (i) {
      return (
        <a>
          <img
            src={`${config.API_URL}/product/${productInfo?.product?.product_image[i]}`}
            className="img-fluid"
            alt="img"
          />
        </a>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  // console.log((productInfo?.review_percentage), 'productInfo');

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  return (
    <div className="page_wrapper">
      <ScrollToTopOnMount />

      <Sidebar />
      <div className="w-100">
        <Navbarinner />
        <div className="right_wrapper">
          <h2 className="h3tag bdr mb-3">{t("PRODUCT_DETAILS")}</h2>
          <div className="pdt_det mb-5">
            <div className="row">
              <div className="col-lg-5 mb-4 mb-lg-0">
                <div className="right_img">
                  <Slider {...settings}>
                    {productInfo?.product?.product_image &&
                      productInfo.product?.product_image.map((item, index) => (
                        <div>
                          <div className="box" key={index}>
                            <img
                              src={`${config.API_URL}/product/${item}`}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        </div>
                      ))}
                  </Slider>
                </div>
              </div>
              <div className="col-lg-7">
                <h3 className="h3tag">
                  {productInfo?.product?.product_title || "-"}
                </h3>
                <div className="sub_tit">
                  {t("PRODUCT_ID")}:
                  <span> {productInfo?.product?.product_id || "-"}</span>|{" "}
                  <span> {productInfo?.product?.review_count || 0}</span>{" "}
                  {t("REVIEWS")} |
                  <div className="d-flex">
                    {productInfo?.reviewarr?.map((item, index) => (
                      <img
                        src={item ? star : star_dark}
                        className="img-fluid"
                        alt="img"
                      />
                    ))}
                    {/* <img src={star} className="img-fluid" alt="img" />
                    <img src={star} className="img-fluid" alt="img" />
                    <img src={star} className="img-fluid" alt="img" />
                    <img src={star} className="img-fluid" alt="img" />
                    <img src={star_dark} className="img-fluid" alt="img" /> */}
                  </div>
                  {productInfo?.product?.review_star}
                  {/* Created:  <img src={time} className="img-fluid" alt="img" />
                  <span> {moment(productInfo?.createdAt).startOf(productInfo?.createdAt).fromNow()}</span> */}
                </div>
                <div className="item_box">
                  <div className="flx">
                    <div>
                      <span className="d-inline-block mb-2">
                        {t("PRODUCT_COST")}
                      </span>
                      <h3 className="h3tag">${productInfo?.actual_cost}</h3>
                    </div>
                    <div>
                      <span className="d-inline-block mb-2">
                        {t("IMPORT_COST")}
                      </span>
                      <h3 className="h3tag">${productInfo?.import_cost}</h3>
                    </div>
                    <div>
                      <span className="d-inline-block mb-2">
                        {t("SELL_COST")}:
                      </span>
                      <h3 className="h3tag">
                        ${productInfo?.sell_cost}{" "}
                        <i
                          onClick={handleClickPop}
                          style={{ fontSize: "14px", cursor: "pointer" }}
                          className="fa"
                        >
                          &#xf044;
                        </i>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <i
                          onClick={showDeletePop}
                          style={{ fontSize: "14px", cursor: "pointer" }}
                          className="fa"
                        >
                          &#xf014;
                        </i>
                      </h3>
                    </div>
                  </div>
                  <div>
                    <span className="d-inline-block mb-2">
                      {t("STORE_NAME")}:
                    </span>
                    <h3 className="h3tag">
                      {productInfo?.store_id?.store_name}-
                      {productInfo?.default_storeid?.Store_name}
                    </h3>
                  </div>

                  <span className="d-block">{t("SHIPS_FROM")}:</span>
                  <p className="shipping">
                    $1.99/ <span className="reg">{t("22BUSINESS_DAYS")} </span>{" "}
                    {t("US")}
                  </p>
                  <p>{productInfo?.product?.description}</p>

                  {/* <div className="custom_tab">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                      <Nav>
                        <Nav.Item>
                          <Nav.Link eventKey="first">Product Info  </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="second">Specifications</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="third">Policy</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="fourth">Reviews</Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content>
                        <Tab.Pane eventKey="first">
                          {productInfo?.product?.product_info}
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">{productInfo?.product?.specification}</Tab.Pane>
                        <Tab.Pane eventKey="third">{productInfo?.product?.policy}</Tab.Pane>
                        <Tab.Pane eventKey="fourth">{productInfo?.product?.review}
                          <div className="review_box" >
                          <GaugeChart
                           id="gauge-chart2"  
                           nrOfLevels={20}
                           percent={0.76} 
                           style={{
                            text: {  // Target the 'text' class
                              color: 'red', // Set the desired color (e.g., blue)
                            },
                          }}
                          colors={["#4e4e4e", "#FFFF00", "#00FF00"]}  />
                          </div>
                        </Tab.Pane>

                      </Tab.Content>
                    </Tab.Container>
                  </div> */}

                  <div className="make_tab">
                    <div className="tabs" style={{ cursor: "pointer" }}>
                      <div
                        className={activeTab === 1 ? "tab active" : "tab"}
                        onClick={() => handleTabClick(1)}
                      >
                        {t("PRODUCT_INFO")}{" "}
                      </div>
                      <div
                        className={activeTab === 2 ? "tab active" : "tab"}
                        onClick={() => handleTabClick(2)}
                      >
                        {t("SPECIFICATIONS")}
                      </div>
                      <div
                        className={activeTab === 3 ? "tab active" : "tab"}
                        onClick={() => handleTabClick(3)}
                      >
                        {t("POLICY")}
                      </div>
                      <div
                        className={activeTab === 4 ? "tab active" : "tab"}
                        onClick={() => handleTabClick(4)}
                      >
                        {t("REVIEWS")}
                      </div>
                    </div>

                    <div className="tab_content">
                      {activeTab === 1 && (
                        <div> {productInfo?.product_info}</div>
                      )}
                      {activeTab === 2 && (
                        <div> {productInfo?.specification}</div>
                      )}
                      {activeTab === 3 && <div>{productInfo?.policy}</div>}
                      {activeTab === 4 && (
                        <div>
                          {productInfo?.review}
                          <div className="review_box">
                            <GaugeChart
                              id="gauge-chart2"
                              nrOfLevels={20}
                              percent={
                                Number(productInfo?.product.review_percentage) /
                                100
                              }
                              // percent={0.50}
                              colors={["#5BE12C", "#F5CD19", "#EA4228"]}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <EditSellCostPop
        show={show}
        setshow={setshow}
        handleClose={handleClose}
        productInfo={productInfo}
      />

      <DeletePop
        showdelete={showdelete}
        setshowdelete={setshowdelete}
        productid={productInfo}
      />
    </div>
  );
}

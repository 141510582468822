import React, { useEffect, useState, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTable from "react-data-table-component";
import { Modal, Dropdown, Row, Col, Form } from "react-bootstrap";
// img
import down from "../assets/images/down.svg";
import calender from "../assets/images/calender.svg";
import profit from "../assets/images/profit.svg";
import tot_order from "../assets/images/tot_order.svg";
import revenue from "../assets/images/revenue.svg";
import new_products from "../assets/images/new_products.svg";
import basket from "../assets/images/basket.svg";
import tag from "../assets/images/tag.svg";
import Sidebar from "../components/sidebar.js";
import MyChart from "../components/linechart.js";
import MyLineChart from "../components/linechart.js";
import Navbarinner from "../components/Navbarinner.js";
import { Link, useNavigate } from "react-router-dom";
import { Updatepayment, getdashBoardOrder } from "../action/user.action.js";
import { getUserStore } from "../redux/features/userstore/userStoreSlice.js";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../redux/features/user/userslice.js";
import moment from "moment";
import { changePassword } from "../action/user.action";
import { toastAlert } from "../utils/toast";
import { useTranslation } from "react-i18next";

let initialstate = {
  oldPassword: "",
  newPassword: "",
  conNewPassword: "",
};

export default function Changepassword() {
  const { t } = useTranslation();

  const [formdata, setfromdata] = useState(initialstate);

  const [message, setMessage] = useState("");
  const [progress, setProgress] = useState("");
  const [passmessage, setpassmessage] = useState("");
  const [ValidateError, setValidateError] = useState({});

  const { oldPassword, newPassword, conNewPassword } = formdata;

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formdata, ...{ [name]: value } };
    setfromdata(formData);
    if (name == "newPassword") {
      handlePassword(value);
    }
  };

  const handlePassword = (passwordValue) => {
    const strengthChecks = {
      length: 0,
      hasUpperCase: false,
      hasLowerCase: false,
      hasDigit: false,
      hasSpecialChar: false,
    };

    strengthChecks.length = passwordValue.length >= 8 ? true : false;
    strengthChecks.hasUpperCase = /[A-Z]+/.test(passwordValue);
    strengthChecks.hasLowerCase = /[a-z]+/.test(passwordValue);
    strengthChecks.hasDigit = /[0-9]+/.test(passwordValue);
    strengthChecks.hasSpecialChar = /[^A-Za-z0-9]+/.test(passwordValue);

    var errormsg = {};
    if (!strengthChecks.length) {
      errormsg = "PASSWORD_MIN";
    } else if (!strengthChecks.hasUpperCase) {
      errormsg = "PASSWORD_UPPER";
    } else if (!strengthChecks.hasLowerCase) {
      errormsg = "PASSWORD_LOWER";
    } else if (!strengthChecks.hasDigit) {
      errormsg = "PASSWORD_NUMERIC";
    } else if (!strengthChecks.hasSpecialChar) {
      errormsg = "PASSWORD_SPECIAL";
    }
    setpassmessage(errormsg);

    let verifiedList = Object.values(strengthChecks).filter((value) => value);

    let strength =
      verifiedList.length == 5
        ? "STRONG"
        : verifiedList.length >= 2
        ? "MEDIUM"
        : "WEAK";

    // setPassword(passwordValue);
    setProgress(`${(verifiedList.length / 5) * 100}%`);
    setMessage(strength);

    // console.log("verifiedList: ", `${(verifiedList.length / 5) * 100}%`);
  };

  const getActiveColor = (type) => {
    if (type === "Strong") return "#8BC926";
    if (type === "Medium") return "#FEBD01";
    return "red";
  };

  const Fromvalidation = async () => {
    try {
      var validateError = {};

      if (oldPassword.trim() == "") {
        validateError.oldPassword = "PASSWORD_REQUIRED";
      }
      if (newPassword.trim() == "") {
        validateError.newPassword = "NEW_PASSWORD_REQUIRED";
      } else if (Object.keys(passmessage).length != 0) {
        validateError.newPassword = passmessage;
      }

      if (conNewPassword.trim() == "") {
        validateError.conNewPassword = "CONFIRM_PASSWORD_REQUIRED";
      } else if (newPassword != conNewPassword) {
        validateError.conNewPassword = "NEW_CONFIRM_MISMATCH";
      }

      setValidateError(validateError);
      return validateError;
    } catch (err) {}
  };

  const handleSumbit = async () => {
    try {
      const check = await Fromvalidation();
      var errorsSize = Object.keys(check).length;
      if (errorsSize == 0) {
        var reqdata = {
          oldPassword: oldPassword,
          newPassword: newPassword,
          confirmNewPassword: conNewPassword,
        };
        var { status, message, error } = await changePassword(reqdata);
        if (status) {
          toastAlert("success", t(message));
          window.location.reload();
        } else {
          var validateError = {};
          validateError.oldPassword = error.oldPassword;
          validateError.newPassword = error.newPassword;
          validateError.conNewPassword = error.confirmNewPassword;
          setValidateError(validateError);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="page_wrapper">
      <Sidebar />
      <div className="w-100">
        <Navbarinner />
        <div className="right_wrapper">
          <h2 class="h3tag bdr mb-3">{t("CHANGE_PASSWORD")}</h2>

          <div className="row">
            <div className="col-lg-6">
              <div className="kyc_box">
                <div className="input_box">
                  <label className="mb-2 sm_txt">{t("CURRENT_PASSWORD")}</label>
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="oldPassword"
                      placeholder={t("CURRENT_PASSWORD")}
                      onChange={handleChange}
                    />
                  </div>
                  {ValidateError.oldPassword && (
                    <span className="text-danger">
                      {t(ValidateError.oldPassword)}
                    </span>
                  )}
                </div>
                <div className="input_box">
                  <label className="mb-2 sm_txt">{t("NEW_PASSWORD")}</label>
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="newPassword"
                      placeholder={t("NEW_PASSWORD")}
                      onChange={handleChange}
                    />
                  </div>
                  {ValidateError.newPassword && (
                    <span className="text-danger">
                      {t(ValidateError.newPassword)}
                    </span>
                  )}
                </div>

                {newPassword.length !== 0 ? (
                  <>
                    <div className="progress-bg">
                      <div
                        className="progress"
                        style={{
                          // marginTop: "px",
                          height: "6px",
                          width: progress,
                          backgroundColor: getActiveColor(t(message)),
                        }}
                      ></div>
                    </div>
                    <p
                      className="message"
                      style={{ color: getActiveColor(t(message)) }}
                    >
                      {t("YOUR_PASSWORD")} {t(message)}
                    </p>
                  </>
                ) : null}

                <div className="input_box">
                  <label className="mb-2 sm_txt">
                    {t("CONFIRM_NEW_PASSWORD")}
                  </label>
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="conNewPassword"
                      placeholder={t("CONFIRM_NEW_PASSWORD")}
                      onChange={handleChange}
                    />
                  </div>{" "}
                  {ValidateError.conNewPassword && (
                    <span className="text-danger">
                      {t(ValidateError.conNewPassword)}
                    </span>
                  )}
                </div>

                <button className="primary_btn" onClick={handleSumbit}>
                  {t("SUBMIT")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

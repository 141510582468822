import React, { useEffect, useState } from "react";
import { InputGroup, Form, Accordion } from "react-bootstrap";
// img
import usr from "../assets/images/usr.svg";

import Sidebar from "../components/sidebar.js";
import Navbarinner from "../components/Navbarinner.js";

import SupportCreate from "../components/support/SupportCreate";
import SupportList from "../components/support/SupportList";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import { getAllFaq } from "../action/user.action";
import { useTranslation } from "react-i18next";

export default function Support() {
  const { t } = useTranslation();

  const [faq, setFaq] = useState([]);

  const fetchData = async () => {
    try {
      const { status, result } = await getAllFaq();
      //   console.log(status, result, '1111');
      if (status) {
        setFaq(result);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {" "}
      <ScrollToTopOnMount />
      <div className="page_wrapper">
        <Sidebar navLink={"/support-ticket"} />
        <div className="w-100">
          <Navbarinner />
          <div className="right_wrapper">
            <div className="row mt-4">
              <div className="col-lg-4">
                <SupportCreate />
              </div>
              <div className="col-lg-8">
                <SupportList />
              </div>
            </div>
            <section class="faq" name="faq">
              <div class="container">
                <div className="row">
                  <div className="col-lg-12 col-xl-12">
                    <div
                    // data-aos="fade-up"
                    //  data-aos-duration="1000"
                    >
                      <h2 class="h2tag text-center">
                        {t("FREQUENTLY_ASKED_QUESTIONS")}?
                      </h2>
                    </div>
                    <div class="accordion" id="accordionExample">
                      {faq &&
                        faq.map((ele, i) => (
                          <div
                            class="accordion-item"
                            // data-aos="fade-up"
                            // data-aos-duration="1000"
                          >
                            <h2 class="accordion-header" id="headingOne">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapseOne_${i}`}
                                aria-expanded="false"
                                aria-controls={`collapseOne_${i}`}
                              >
                                {ele.question}?
                              </button>
                            </h2>
                            <div
                              id={`collapseOne_${i}`}
                              class="accordion-collapse collapse"
                              aria-labelledby="headingOne"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="accordion-body">
                                <p>{ele.answer}</p>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { accountReset } from "../redux/features/user/userslice";
import { getUserProfile } from "../redux/features/user/userslice";
import { getDefStore } from "../redux/features/defaultstore/defaultStoreSlice";
import { getUserStore } from "../redux/features/userstore/userStoreSlice";
import { getUserDraft } from "../redux/features/draft/mydraftslice";
import { getMyproduct } from "../redux/features/getmyProduct/myProductSlice";
import {
  Loginuser,
  getGeoInfoData,
  isValidCampign,
} from "../action/user.action";
import { setUserToken } from "../utils/cookies";
import { getNotify } from "../redux/features/notification/notificationSlice";
import { toastAlert } from "../utils/toast";
import { useTranslation } from "react-i18next";
import { getUserWallet } from "../redux/features/wallet/walletslice";

function UrlLogin() {
  const [cookies, setCookie, removeCookie] = useCookies(["name"]);
  const [validateError, setValidateError] = useState({});

  const { token } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const navigate = useNavigate();
  useEffect(() => {
    Removecookies();
    checkValidFor();
  }, [token]);

  const Removecookies = async () => {
    removeCookie("userToken");
    removeCookie("loggedin");
    dispatch(accountReset(null));
  };

  const checkValidFor = async () => {
    try {
      if (token) {
        // console.log("Hi");

        const payload = {
          refCode: token,
        };
        const check = await isValidCampign(payload);
        // console.log(check, "check");
        var reqdata = {
          email: check.email,
          password: check.pwd,
          type: "Admin",
        };
        // console.log(reqdata, 'reqdata');
        const result = await Loginuser(reqdata, dispatch);
        // console.log(result,"result");
        if (result.status == false) {
          var validateError = {};
          validateError.email = result.message.email;
          validateError.password = result.message.password;
          setValidateError(validateError);
        } else {
          // router.push("/dashboard");
          setCookie("loggedin", true, { path: "/dashboard" });

          setUserToken(result.result.token, false);

          dispatch(getUserProfile());
          dispatch(getDefStore());
          dispatch(getUserStore());
          dispatch(getUserDraft());
          dispatch(getMyproduct());
          dispatch(getNotify());
          dispatch(getUserWallet());

          sessionStorage.removeItem("USER_AUTH_VERIFY_TOKEN_DATA");
          setTimeout(() => {
            navigate("/marketplace", {
              state: { _id: "665c4895072d9e5e06f495e3" },
            });
          }, 1000);

          toastAlert("success", t("LOGIN_SUCCESS"));
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div>loading</div>
      {validateError.password && (
        <span className="text-danger">{validateError.password}</span>
      )}
      {validateError.email && (
        <span className="text-danger">{validateError.email}</span>
      )}
    </>
  );
}

export default UrlLogin;

import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import { useTranslation } from "react-i18next";

// img
import t1 from "../assets/images/t1.png";
import t2 from "../assets/images/t2.png";
import t3 from "../assets/images/t3.png";
import t4 from "../assets/images/t4.png";

import Navbarinner from "../components/Navbarinner.js";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Community() {
  const { t } = useTranslation();

  return (
    <div>
      <Navbar />
      <div className="common_page">
        <div className="container">
          <h1 className="h2tag mb-4">
            <strong>{t("COMMUNITY")} </strong>
          </h1>
          <h2>
            <strong>
              <em>{t("UNLOCK_POTENTIAL")}</em>
            </strong>
          </h2>
          <h3>
            <strong>&middot;&nbsp;</strong>
            <strong>
              <strong>
                {t("COMMUNITY")} {t("SUPPORT")}
              </strong>
            </strong>
          </h3>
          <p>{t("COMMUNITY_PARA1")}</p>
          <h3>
            <strong>&middot;&nbsp;</strong>
            <strong>
              <strong>{t("COMMUNITY_HEAD2")}</strong>
            </strong>
          </h3>
          <p>{t("COMMUNITY_PARA2")}</p>
          <h3>
            <strong>&middot;&nbsp;</strong>
            <strong>
              <strong>{t("COMMUNITY_HEAD3")}</strong>
            </strong>
          </h3>
          <p>{t("COMMUNITY_PARA3")}</p>
          <h3>
            <strong>&middot;&nbsp;</strong>
            <strong>
              <strong>{t("COMMUNITY_HEAD4")}</strong>
            </strong>
          </h3>
          <p>{t("COMMUNITY_PARA4")}</p>
          <h3>
            <strong>&middot;&nbsp;</strong>
            <strong>
              <strong>{t("COMMUNITY_HEAD5")}</strong>
            </strong>
          </h3>
          <p>{t("COMMUNITY_PARA5")}</p>
          <h3>
            <strong>&middot;&nbsp;</strong>
            <strong>
              <strong>{t("COMMUNITY_HEAD6")}</strong>
            </strong>
          </h3>
          <p>{t("COMMUNITY_PARA6")}</p>
          <h2>
            <strong>
              <em>
                <strong>
                  <em>{t("COMMUNITY_HEAD7")}</em>
                </strong>
              </em>
            </strong>
          </h2>
          <p>{t("COMMUNITY_PARA7")}</p>
          <p className="text-center mt-5">{t("TESTIMONIALS")}</p>
          <h3>
            {" "}
            <strong>{t("WHAT_OTHERS")}</strong>{" "}
          </h3>
          <div className="box">
            <div className="img_box">
              <img src={t1} className="img-fluid" alt="logo" />
            </div>
            <div>
              <h5>{t("SAYS_HEAD1")}</h5>
              <p>{t("SAYS_PARA1")}</p>
            </div>
          </div>

          <div className="box">
            <div className="img_box">
              <img src={t2} className="img-fluid" alt="logo" />
            </div>
            <div>
              <h5>{t("SAYS_HEAD2")}</h5>
              <p>{t("SAYS_PARA2")}</p>
            </div>
          </div>

          <div className="box">
            <div className="img_box">
              <img src={t2} className="img-fluid" alt="logo" />
            </div>
            <div>
              <h5>{t("SAYS_HEAD3")}</h5>
              <p>{t("SAYS_PARA3")}</p>
            </div>
          </div>

          <div className="box">
            <div className="img_box">
              <img src={t3} className="img-fluid" alt="logo" />
            </div>
            <div>
              <h5>{t("SAYS_HEAD4")}</h5>
              <p>{t("SAYS_PARA4")}</p>
            </div>
          </div>

          <div className="box">
            <div className="img_box">
              <img src={t2} className="img-fluid" alt="logo" />
            </div>
            <div>
              <h5>{t("SAYS_HEAD5")}</h5>
              <p>{t("SAYS_PARA5")}</p>
            </div>
          </div>

          <div className="box">
            <div className="img_box">
              <img src={t4} className="img-fluid" alt="logo" />
            </div>
            <div>
              <h5>{t("SAYS_HEAD6")}</h5>
              <p>{t("SAYS_PARA6")}</p>
            </div>
          </div>

          <div className="box">
            <div className="img_box">
              <img src={t2} className="img-fluid" alt="logo" />
            </div>
            <div>
              <h5>{t("SAYS_HEAD7")}</h5>
              <p>{t("SAYS_PARA7")}</p>
            </div>
          </div>

          <div className="box">
            <div className="img_box">
              <img src={t2} className="img-fluid" alt="logo" />
            </div>
            <div>
              <h5>{t("SAYS_HEAD8")}</h5>
              <p>{t("SAYS_PARA8")}</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export const setTheme = async (theme) => {
  if (theme == "dark") {
    document.body.classList.add("light_theme");
  } else {
    document.body.classList.remove("light_theme");
  }
  localStorage.setItem("theme", theme);
  return theme;
};

export const getTheme = () => {
  let theme = localStorage.getItem("theme");
  if (theme) {
    return theme;
  }
  return "dark";
};

export const changeTheme = (theme) => {
  if (theme == "dark") {
    document.body.classList.add("light_theme");
  } else if (theme == "light") {
    document.body.classList.remove("light_theme");
  }
  return true;
};

export const setLang = async (value) => {
  localStorage.setItem("lang", value);
  return true;
};

export const getLang = () => {
  if (localStorage.getItem("lang")) {
    return localStorage.getItem("lang");
  }
  return "";
};

import React, { useEffect, useRef, useState } from "react";
import { InputGroup, Form, Accordion } from "react-bootstrap";
import usr from "../../assets/images/usr.svg";

import {
  userTicketList,
  usrReplyMsg,
  closeTicket,
} from "../../action/user.action";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { toastAlert } from "../../utils/toast";
import config from "../../config/index";
import $ from "jquery";
import { useTranslation } from "react-i18next";

function SuportList() {
  const [ticketlist, setticketlist] = useState([]);
  const user = useSelector((state) => state.account);
  const [reply, setreply] = useState("");
  const [loadApi, setloadApi] = useState(false);
  const [file, setFile] = useState();
  const ref = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    getTicketList();
    setloadApi(false);
  }, [loadApi]);

  const getTicketList = async () => {
    try {
      var { status, result } = await userTicketList();
      if (status) {
        setticketlist(result.ticketList);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const replaychange = (e) => {
    setreply(e.target.value);
  };

  async function replysend(iid, resid) {
    if (reply && reply != "" && reply != null && reply != undefined) {
      var reqdata = {
        ticketId: iid,
        message: reply,
        receiverId: resid,
        image: file ? file : "",
      };

      var lists = await usrReplyMsg(reqdata);

      if (lists && lists.status && lists.message) {
        toastAlert("success", t("REPLY_SUBMIT"));
        setreply("");
        setloadApi(true);
        // setTimeout(() => {
        //     window.location.reload()
        // }, 1500)
        $("input[name='image']").val("");
      } else {
        if (lists.message) {
          toastAlert("error", t(lists.message));
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      }
    } else {
      toastAlert("error", t("MESSAGE_ERROR"));
    }
  }

  const handelCloseTicket = async (iid) => {
    try {
      let reqdata = {
        ticketId: iid,
      };
      const { result, status, message } = await closeTicket(reqdata);
      if (status) {
        toastAlert("success", t("CLOSE_SUCCESS"));
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        if (message) {
          toastAlert("error", t(message));
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      }
    } catch (err) {}
  };

  const clickHandler = async () => {
    ref.current.click();
  };

  const imageHanlder = ({ target: { files } }) => {
    if (!files.length) {
      return;
    }

    let picture = files[0];
    if (!picture.type.toLowerCase().startsWith("image/")) {
      throw Error(t("INVALID_IMAGE"));
    }
    setFile(picture);
    // let formData = { ...formdata, ...{ file: picture } };
    // setformdata(formData);
  };

  return (
    <div className="custom_faq">
      <div className={"box"}>
        <h5>{t("TICKET_DETAILS")}</h5>
        <Accordion defaultActiveKey="0">
          {ticketlist &&
            ticketlist.length > 0 &&
            ticketlist.map((item, index) => (
              <Accordion.Item eventKey={index}>
                <Accordion.Header>
                  <div className="acc_flx">
                    <p>
                      <span>{t("SUB")}:</span> {item?.categoryName} {t("ISSUE")}{" "}
                      -{item?.reply[0].message}{" "}
                    </p>
                    <div>
                      <span>
                        [ {t("TICKET_ID")}: #{item?.tickerId} ]
                      </span>{" "}
                      <span>
                        [ {t("STATUS")}:{" "}
                        <p
                          className={
                            item?.status == "open"
                              ? "badge bg-success rounded-pill"
                              : "badge bg-danger rounded-pill"
                          }
                        >
                          {item?.status == "open" ? t("OPEN") : t("CLOSED")}
                        </p>{" "}
                        ]{" "}
                      </span>{" "}
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className={"ctn"}>
                    <img
                      src={usr}
                      className={`img-fluid mb-2`}
                      alt="img"
                      height={20}
                    />
                    <div>
                      {item?.status != "closed" && (
                        <a
                          href="javascript:void(0)" className="atag"
                          onClick={() => handelCloseTicket(item._id)}
                        >
                          {t("CLOSE_TICKET")}
                        </a>
                      )}

                      {/* <h6>Mike Smith</h6> */}
                      <span className={`mb-3 d-block ${"grey"} `}>
                        {moment(item?.createdAt).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}
                      </span>

                      <div className={"chat_container"}>
                        <div className={"chat_messages"}>
                          {(item.reply > 0 ? item.reply : item.reply).map(
                            (replay) => (
                              <>
                                {replay.senderId != user.id ? (
                                  <div className={"message"}>
                                    <h6 className="badge bg-success rounded-pill">
                                      {t("ADMIN")}
                                    </h6>
                                    <div className={"message_text"}>
                                      {" "}
                                      {replay.message}
                                    </div>
                                    {replay.image != "" && (
                                      <a
                                        className={"primary_btn"}
                                        target="_blank"
                                        href={`${config.API_URL}/support/${replay.image}`}
                                      >
                                        {t("FILE")}
                                      </a>
                                    )}

                                    <div className={"message_time"}>
                                      {" "}
                                      {moment(replay.createdAt).format("lll")}
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}

                                {replay.senderId == user.id ? (
                                  <div className={`${"admin"} ${"message"}`}>
                                    <h6 className="badge bg-success rounded-pill">
                                      {t("YOU")}
                                    </h6>
                                    <div className={"message_text"}>
                                      {" "}
                                      {replay.message}
                                    </div>

                                    {replay.image != "" && (
                                      <a
                                        className={"primary_btn"}
                                        target="_blank"
                                        href={`${config.API_URL}/support/${replay.image}`}
                                      >
                                        {t("FILE")}
                                      </a>
                                    )}

                                    <div className={"message_time"}>
                                      {" "}
                                      {moment(replay.createdAt).format("lll")}
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            )
                          )}
                        </div>
                        {item?.status != "closed" && (
                          <div className={"chat_input"}>
                            <div className={`w-100 me-2  ${"input_box"}`}>
                              <div className="d-flex align-items-center">
                                <Form.Control
                                  type="text"
                                  value={reply}
                                  onChange={replaychange}
                                ></Form.Control>
                                <div className="file_icon">
                                  <i class="fas fa-file"></i>
                                  <input
                                    type="file"
                                    ref={ref}
                                    onChange={imageHanlder}
                                    name="image"
                                  />
                                </div>
                                {/* <p> Note: MAX 1 MB (only .jpeg, .png, .jpg, .pdf)</p> */}
                              </div>
                            </div>

                            <div className="blue_btn">
                              <button
                                className={"primary_btn"}
                                onClick={() => {
                                  replysend(
                                    item._id,
                                    item?.reply[0].receiverId
                                  );
                                }}
                              >
                                {t("SEND")}
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            ))}
        </Accordion>
      </div>
    </div>
  );
}

export default SuportList;

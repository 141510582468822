import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { VerifyEmail } from "../action/user.action";
import { useNavigate } from "react-router-dom";

export default function Emailverification() {
  const navigate = useNavigate();

  const { id } = useParams();

  const [status, setstatus] = useState();

  useEffect(() => {
    VerifyEmailChange();
  }, [id]);

  const VerifyEmailChange = async () => {
    try {
      const result = await VerifyEmail(id);
      // console.log(result,"resultresult");
      if (result.status) {
        setstatus(true);
        setTimeout(() => {
          // window.location.href = "/login"
          navigate("/login", { state: { result: result.result } });
        }, 0);
      }
    } catch (err) {}
  };

  return (
    <>
      {status ? (
        <center>
          {/* <h4>Email Verified Successfully</h4>
                    <a href="/login">Login</a> */}
        </center>
      ) : (
        ""
      )}
    </>
  );
}

import isEmpty from "../../lib/isEmpty";
export const Validation = (value) => {
  let errors = {};
  if (isEmpty(value.toCurrencyId)) {
    errors.toCurrencyId = "TO_CURRENCY_REQUIRED";
  }
  if (isEmpty(value.amount)) {
    errors.amount = "AMOUNT_REQUIRED";
  } else if (isNaN(value.amount)) {
    errors.amount = "VALID_AMOUNT";
  } else if (value.amount <= 0) {
    errors.amount = "AMOUNT_CAN'T_ZERO";
  }

  return errors;
};

import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { getMapchart } from "../../action/user.action";
import { regional } from "../../components/globlemap/features";
import { useTranslation } from "react-i18next";

export const data = [
  [
    "Country",
    "Orders",
    // , { role: 'tooltip', type: 'string', p: { html: true } }
  ],
];

export default function MapChart(props) {
  const { t } = useTranslation();

  let { selectedValue } = props;
  // console.log(selectedValue, 'selectedValue');

  useEffect(() => {
    getdata();
  }, [selectedValue]);

  const getdata = async () => {
    let store = selectedValue?._id || "all";

    let { status, result } = await getMapchart({ store });
    if (status) {
      // console.log(result, "fdd");
      for (let i = 0; i < result.length; i++) {
        data.push([
          result[i]?.country_code,
          //  result[i]?.shopDescr[0]?.total,

          `${t("ORDER")}: ${result[i]?.shopDescr[0]?.total || 0},${t(
            "STORE"
          )} : ${result[i]?.store_name}`,
        ]);
      }
    }
  };

  const options = {
    region:
      regional[selectedValue == "all" ? "all" : selectedValue.country_code],
    colorAxis: {
      colors: [
        "#51ca92",
        "#7bca51",
        "#544cdb",
        "#f11a11",
        "#00153f",
        "#02609f",
      ],
    },
    backgroundColor: "transparent",
    datalessRegionColor: "#f1df20",
    defaultColor: "#e39a65",
    displayMode: "auto",

    zoomLevel: 10,
  };

  // console.log(data, "data");

  return (
    <Chart
      chartEvents={[
        {
          eventName: "select",
          callback: ({ chartWrapper }) => {
            const chart = chartWrapper.getChart();
            const selection = chart.getSelection();
            if (selection.length === 0) return;
            const region = data[selection[0].row + 1];
            // console.log("Selected : " + region);
          },
        },
      ]}
      chartType="GeoChart"
      // width="100%"
      height="400px"
      data={data}
      options={options}
    />
  );
}

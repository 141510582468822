import mainReducer from "./mainReducer";
import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import autoMergeLevel1 from "redux-persist/lib/stateReconciler/autoMergeLevel1";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import { thunk } from "redux-thunk";

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel1,
  whitelist: [
    "auth",
    "account",
    "defaultstore",
    "userstore",
    "userdraft",
    "myproduct",
    "notify",
    "wallet",
    "priceconversion",
    "currency",
  ],
  blacklist: [],
  debug: true,
};

const store = configureStore({
  reducer: persistReducer(persistConfig, mainReducer()),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(thunk),
  devTools: true,
});

store.asyncReducers = {};
export const persistor = persistStore(store);

export default store;

import axios from "./axios";

import { setAuth } from "../redux/features/auth/authslice";
import config from "../config/index"

export const registerUser = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/user/registration`,
            data,
        });
        //// console.log(respData, "respDatakkkk");
        return {
            loading: true,
            result: respData.data.result,
            status: respData.data.success,
            //   error: respData.data.errors,
            message: respData.data.message,
        };
    } catch (err) {
        return {
            loading: false,
            status: err.response.data.success,
            error: err.response.data.errors,
        };
    }
};


export const Loginuser = async (data, dispatch) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/user/login`,
            data,
        });
        // console.log(respData, "respDatakkkk");
        dispatch(setAuth(true));
        return {
            loading: true,
            result: respData.data.result,
            message: respData.data.message,
            status: respData.data.status,
            //   error: respData.data.errors,
        };
    } catch (err) {
        return {
            loading: false,
            status: err.response.data.status,
            message: err.response.data.message,
        };
    }
};



export const viewUserProfile = async () => {
    try {
        let respData = await axios({
            method: "get",
            url: `/api/user/userProfile`,
        });

        // console.log(respData, 'respDatarespDatarespDatarespData12');
        return {
            status: respData.data.status,
            result: respData.data.result,
            message: respData.data.message,
        };
    } catch (err) {
        return {
            status: err.response.data.status,
            error: err.response.data.mesaage,
        };
    }
};




export const getProductCat = async () => {
    try {
        let respData = await axios({
            method: "get",
            url: `/api/user/getProductCat`,
        });
        return {
            status: respData.data.status,
            result: respData.data.result,
            message: respData.data.message,
        };
    } catch (err) {
        return {
            status: err.response.data.status,
            error: err.response.data.mesaage,
        };
    }
};


export const getProductList = async (data, sort, search) => {
    try {
        // console.log(data, "params");

        let respData = await axios({
            method: "get",
            url: `/api/user/getProductList?id=` + data + `&search=` +
                search + `&sort=` +
                sort,

        });
        return {
            status: respData.data.status,
            result: respData.data.result,
            message: respData.data.message,
        };
    } catch (err) {
        return {
            status: err.response.data.status,
            error: err.response.data.mesaage,
        };
    }
};





export const getAllStores = async (sort, search) => {
    try {
        // console.log(data, "params");

        let respData = await axios({
            method: "get",
            url: `/api/user/getAllStore?search=` + search + `&sort=` + sort,

        });
        return {
            status: respData.data.status,
            result: respData.data.result,
            message: respData.data.message,
        };
    } catch (err) {
        return {
            status: err.response.data.status,
            error: err.response.data.mesaage,
        };
    }
};


export const getvipProductList = async (data, sort, search) => {
    try {
        // console.log(data, "params");

        let respData = await axios({
            method: "get",
            url: `/api/user/getVipProductList?id=` + data + `&search=` +
                search + `&sort=` +
                sort,

        });
        return {
            status: respData.data.status,
            result: respData.data.result,
            message: respData.data.message,
        };
    } catch (err) {
        return {
            status: err.response.data.status,
            error: err.response.data.mesaage,
        };
    }
};



export const getDefaultStore = async () => {
    try {
        let respData = await axios({
            method: "get",
            url: `/api/user/getDefaultStore`,
        });
        return {
            status: respData.data.status,
            result: respData.data.result,
            message: respData.data.message,
        };
    } catch (err) {
        return {
            status: err.response.data.status,
            error: err.response.data.mesaage,
        };
    }
};


export const getMyStore = async () => {
    try {
        let respData = await axios({
            method: "get",
            url: `/api/user/getMyStore`,
        });

        // console.log(respData,'getMyStoregetMyStoregetMyStore');
        return {
            status: respData.data.status,
            result: respData.data.result,
            message: respData.data.message,
        };
    } catch (err) {
        return {
            status: err.response.data.status,
            error: err.response.data.mesaage,
        };
    }
};




export const userCreateStore = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/user/userCreateStore`,
            data,
        });
        return {
            status: respData.data.status,
            message: respData.data.message,
        };
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message,
        };
    }
};



export const storeBuy = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/user/storeBuy`,
            data,
        });
        return {
            status: respData.data.status,
            message: respData.data.message,
        };
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message,
        };
    }
};



export const ProductInfo = async (data) => {
    try {
        let respData = await axios({
            method: "get",
            url: `/api/user/getProductInfo?id=` + data,
        });
        return {
            status: respData.data.status,
            message: respData.data.message,
            result: respData.data.result,
            percentage: respData.data.percentage,
        };
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message,
        };
    }
};




export const setMyDraft = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/user/setMyDraft`,
            data,
        });
        // console.log(respData, "respData");
        return {
            status: respData.data.status,
            message: respData.data.message,

        };
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message,
        };
    }
};



export const getMyDraft = async () => {
    try {
        let respData = await axios({
            method: "get",
            url: `/api/user/getMyDraft`,

        });
        // console.log(respData, "respData 11111111111");
        return {
            status: respData.data.status,
            result: respData.data.result,

        };
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message,
        };
    }
};


export const importToStore = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/user/importToStore`,
            data,
        });
        // console.log(respData, "respData");
        return {
            status: respData.data.status,
            message: respData.data.message,

        };
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message,
        };
    }
};

export const getMystoreProduct = async () => {
    try {
        let respData = await axios({
            method: "get",
            url: `/api/user/getMystoreProList`,

        });
        // console.log(respData, "respData");
        return {
            status: respData.data.status,
            result: respData.data.result,

        };
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message,
        };
    }
};





export const deleteDraft = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/user/deleteDraft`,
            data
        });

        return {
            status: respData.data.status,
            message: respData.data.message,

        };
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message,
        };
    }
};





export const bulkImportProduct = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/user/bulkImportProduct`,
            data
        });

        return {
            status: respData.data.status,
            message: respData.data.message,
            il8: respData.data.il8,
        };
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message,
            il8: err.response.data.il8,
        };
    }
};



export const getMyProductInfo = async (data) => {
    try {
        let respData = await axios({
            method: "get",
            url: `/api/user/getMyProductInfo?id=` + data,
        });
        // console.log(respData, "respData");
        return {
            status: respData.data.status,
            message: respData.data.message,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message,
        };
    }
};

export const renameStore = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/user/renameStore`,
            data
        });

        return {
            status: respData.data.status,
            message: respData.data.message,

        };
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message,
        };
    }
};



export const CreateInstant = async (data) => {

    try {
        const response = await axios({
            method: 'post',
            url: `/api/user/createpaymentintent`,
            data,
        });


        return {
            status: response.data.status,
            // message: respData.data.message,
            result: response.data.clientSecret,
        };
        return response.data
    } catch (error) {
        // console.log(error);
    }
}



export const Updatepayment = async (data) => {
    try {
        const response = await axios({
            method: 'put',
            url: '/api/user/checkPayment',
            data: data
        })


        return {
            status: response.data.status,
            result: response.data.clientSecret,
        };
    } catch (error) {
        // console.log(error);
    }

}





export const getSubscribePack = async () => {
    try {
        let respData = await axios({
            method: "get",
            url: `/api/user/getSubscribePack`,

        });
        // console.log(respData, "respData");
        return {
            status: respData.data.status,
            result: respData.data.result,

        };
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message,
        };
    }
};
export const planSubscribtion = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/user/planSubscribtion`,
            data
        });

        return {
            status: respData.data.status,
            message: respData.data.message,

        };
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message,
        };
    }
};


export const getAdsPack = async () => {
    try {
        let respData = await axios({
            method: "get",
            url: `/api/user/getAdsPack`,

        });
        // console.log(respData, "respData");
        return {
            status: respData.data.status,
            result: respData.data.result,

        };
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message,
        };
    }
};
export const AdsplanSubscribtion = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/user/AdsplanSubscribtion`,
            data
        });

        return {
            status: respData.data.status,
            message: respData.data.message,

        };
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message,
        };
    }
};


export const getshipPack = async () => {
    try {
        let respData = await axios({
            method: "get",
            url: `/api/user/getshipPack`,

        });
        // console.log(respData, "respData");
        return {
            status: respData.data.status,
            result: respData.data.result,

        };
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message,
        };
    }
};
export const shipplanSubscribtion = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/user/shipplanSubscribtion`,
            data
        });

        return {
            status: respData.data.status,
            message: respData.data.message,

        };
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message,
        };
    }
};


// getAdsPack
// AdsplanSubscribtion

export const getOrderlist = async (data) => {
    try {
        let respData = await axios({
            method: "get",
            url:
                `/api/user/getOrderlist?page=` +
                data.page +
                `&limit=` +
                data.limit +
                `&search=` +
                data.search +
                `&startdate=` +
                data.startdate +
                `&enddate=` +
                data.enddate +
                `&store=` +
                data.store
        });
        // console.log(respData, "respData");
        return {
            status: respData.data.status,
            result: respData.data.result,
            totalrecords: respData.data.totalrecords,
            storelist: respData.data.storelist
        };
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message,
        };
    }
};



export const getSupportCategory = async () => {
    try {
        let respData = await axios({
            method: "get",
            url: `/api/user/getSptCat`,

        });
        // console.log(respData, "respData");
        return {
            status: respData.data.status,
            result: respData.data.result,

        };
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message,
        };
    }
}


export const createticket = async (data) => {
    try {

        // console.log(data, "data");
        let respData = await axios({
            method: "post",
            url: `/api/user/createNewTicket`,
            data,
        });
        // console.log(respData);

        return {
            status: respData.data.status,
            message: respData.data.message,
        };
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message,
        };
    }
};


export const userTicketList = async () => {
    try {
        let respData = await axios({
            method: "get",
            url: `/api/user/userTicketList`,

        });
        return {
            status: respData.data.status,
            result: respData.data.result,

        };
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message,
        };
    }
}


export const usrReplyMsg = async (data) => {
    try {

        const formData = new FormData()

        formData.append('ticketId', data.ticketId)
        formData.append('message', data.message)
        formData.append('receiverId', data.receiverId)
        formData.append('image', data.image)


        // console.log(data, "data");
        let respData = await axios({
            method: "post",
            url: `/api/user/usrReplyMsg`,
            data: formData,
        });
        // console.log(respData, "gggg");

        return {
            status: respData.data.status,
            message: respData.data.message,
        };
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message,
        };
    }
};

export const closeTicket = async (data) => {
    try {

        // console.log(data, "data");
        let respData = await axios({
            method: "post",
            url: `/api/user/closeTicket`,
            data,
        });
        // console.log(respData, "gggg");

        return {
            status: respData.data.status,
            message: respData.data.message,
        };
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message,
        };
    }
};



export const ForgetPassword = async (data) => {
    try {

        // console.log(data, "data");
        let respData = await axios({
            method: "post",
            url: `/api/user/forgotPassword`,
            data,
        });

        return {
            status: respData.data.status,
            message: respData.data.message,
        };
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message,
        };
    }
};



export const CheckresetPasswordLink = async (data) => {
    try {

        let respData = await axios({
            method: "get",
            url: `/api/user/CheckresetPasswordLink`,
            params: data
        });

        return {
            status: respData.data.status,
            message: respData.data.message,
        };
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message,
        };
    }
};



export const resetPassword = async (data) => {
    try {

        let respData = await axios({
            method: "post",
            url: `/api/user/resetPassword`,
            data,
        });

        return {
            status: respData.data.status,
            message: respData.data.message,
        };
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message,
        };
    }
};


export const VerifyEmail = async (token) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/user/confirmMail/${token}`,
        });
        return {
            loading: true,
            result: respData.data.result,
            status: respData.data.status,
            message: respData.data.message,

        };
    } catch (err) {
        return {
            loading: false,
            status: err.response.data.status,
            error: err.response.data.errors,
        };
    }
}

export const isValidCampign = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/user/isValidCampign`,
            data,
        });
        //// console.log(respData, "respDatakkkk");
        return {
            loading: true,
            result: respData.data.status,
            email: respData.data.email,
            pwd: respData.data.pwd,
        };
    } catch (err) {
        return {
            loading: false,
            status: err.response.data.status,
            error: err.response.data.errors,
        };
    }
};

export const resendMail = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/user/resendMail`,
            data,
        });
        return {
            status: respData.data.status,
            message: respData.data.message,
        };
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message,
        };
    }
};


export const getSiteSetting = async () => {
    try {
        let respData = await axios({
            method: "get",
            url: `/api/user/getSiteSetting`,

        });

        // console.log(respData, 'respDatarespData');
        return {
            status: respData.data.status,
            result: respData.data.result,

        };
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message,
        };
    }
}




export const getdashBoardOrder = async (data) => {
    try {

        let respData = await axios({
            method: "get",
            url: `/api/user/getdashBoardOrder`,
            params: data
        });

        // console.log(respData, 'sakthibala111');/
        return {
            status: respData.data.status,
            result: respData.data.result,

        };
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message,
        }
    }
}


export const withDrawRequest = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/user/WidthDrawAmount/`,
            data
        });

        return {
            status: respData.data.status,
            message: respData.data.message,

        };

    } catch (error) {
        // console.log(error);
        return {
            status: error.response.data.status,
            message: error.response.data.message,
        }
    }
}






export const updateNotifyCount = async () => {
    try {
        let respData = await axios({
            method: "get",
            url: `/api/user/updateNotifyCount/`,
        });

        // console.log(respData, 'Received message');

        return {
            status: respData.data.success,
            message: respData.data.message,
        };

    } catch (error) {
        console.log(error);
        return {
            status: error.response.data.status,
            message: error.response.data.message,
        }
    }
}

export const getDepostlist = async (data) => {
    try {
        let respData = await axios({
            method: "get",
            url:
                `/api/user/getDepostlist?page=` +
                data.page +
                `&limit=` +
                data.limit +
                `&search=` +
                data.search +
                `&startdate=` +
                data.startdate +
                `&enddate=` +
                data.enddate,
        });
        // console.log(respData, "respData");
        return {
            status: respData.data.status,
            result: respData.data.result,
            totalrecords: respData.data.totalrecords,

        };
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message,
        };
    }
};

export const getWithdrawHis = async (data) => {
    try {
        let respData = await axios({
            method: "get",
            url:
                `/api/user/getWithdrawHis?page=` +
                data.page +
                `&limit=` +
                data.limit +
                `&search=` +
                data.search +
                `&startdate=` +
                data.startdate +
                `&enddate=` +
                data.enddate,
        });
        // console.log(respData, "respData");
        return {
            status: respData.data.status,
            result: respData.data.result,
            totalrecords: respData.data.totalrecords,

        };
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message,
        };
    }
};



export const changePassword = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/user/changePassword`,
            data
        });

        return {
            status: respData.data.status,
            message: respData.data.message,
            error: respData.data.errors,
        };
    } catch (error) {
        console.log(error);
        return {
            status: error.response.data.status,
            message: error.response.data.message,
            error: error.response.data.errors,

        }
    }
}


export const setBankDetail = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/user/setBankDetail`,
            data
        });

        return {
            status: respData.data.status,
            message: respData.data.message,
            error: respData.data.errors,
        };
    } catch (error) {
        console.log(error);
        return {
            status: error.response.data.status,
            message: error.response.data.message,
            error: error.response.data.errors,

        }
    }
}



export const EditSellPrice = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/user/EditSellPrice`,
            data
        });

        return {
            status: respData.data.status,
            message: respData.data.message,
        };
    } catch (error) {
        console.log(error);
        return {
            status: error.response.data.status,
            message: error.response.data.message,
            error: error.response.data.errors,

        }
    }
}



export const deleteMyProduct = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/user/deleteMyProduct`,
            data
        });

        return {
            status: respData.data.status,
            message: respData.data.message,
        };
    } catch (error) {
        console.log(error);
        return {
            status: error.response.data.status,
            message: error.response.data.message,
            error: error.response.data.errors,

        }
    }
}


export const getUserNotification = async (data) => {
    try {

        let respData = await axios({
            method: "get",
            url: `/api/user/getUserNotification`,
            params: data
        });

        return {
            status: respData.data.status,
            result: respData.data.result,

        };
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message,
        }
    }
}



export const getGeoInfoData = async (data) => {
    try {
        let respData = await axios({
            method: "get",
            url: `${config.getGeoInfo}`,
        });

        return {
            status: "success",
            loading: false,
            result: respData.data,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors,
        };
    }
};



export const getChartData = async (data) => {
    try {

        let respData = await axios({
            method: "get",
            url: `/api/user/getChartData`,
            params: data
        });

        return {
            status: respData.data.status,
            result: respData.data.result,

        };
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message,
        }
    }
}


export const shopMaintenance = async () => {
    try {

        let respData = await axios({
            method: "get",
            url: `/api/user/shopMaintenance`,
        });

        return {
            status: respData.data.status,
            result: respData.data.result,

        };
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message,
        }
    }
}


export const getMapchart = async (data) => {
    try {

        let respData = await axios({
            method: "get",
            url: `/api/user/getMapchart`,
            params: data
        });

        return {
            status: respData.data.status,
            result: respData.data.result,

        };
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message,
        }
    }
}


export const getAccountHistory = async (data) => {
    try {
        let respData = await axios({
            method: "get",
            url:
                `/api/user/getAccountHistory?page=` +
                data.page +
                `&limit=` +
                data.limit +
                `&search=` +
                data.search,
        });
        // console.log(respData, "respData");
        return {
            status: respData.data.status,
            result: respData.data.result,
            totalrecords: respData.data.totalrecords,

        };
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message,
        };
    }
};



export const getMyProList = async (sort) => {
    try {
        let respData = await axios({
            method: "get",
            url:
                `/api/user/getMyProList?sort=` +
                sort

        });
        // console.log(respData, "respData");
        return {
            status: respData.data.status,
            result: respData.data.result,
            totalrecords: respData.data.totalrecords,

        };
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message,
        };
    }
};

// getSubscription planeDate

export const getPlanData = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/user/getSubPlandetails`,
            data: data

        });
        // console.log(respData, "respDataresult getPlanData");
        return {
            status: respData.data.status,
            result: respData.data.result,


        };
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message,
        };
    }
}


//createReferral

export const createReferral = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/user/createReferral`,
            data: data

        });
        // console.log(respData, "respDataresult getPlanData");
        return {
            status: respData.data.status,
            message: respData.data.message,

        };
    } catch (error) {
        return {
            status: error.response.data.status,
            message: error.response.data.message,
        };
    }
}


//getAllFaq


export const getAllFaq = async (data) => {
    try {
        let respData = await axios({
            method: "get",
            url: `/api/user/getAllFaq`,
            // data: data

        });
        // console.log(respData, "respDataresult result");
        return {
            status: respData.data.status,
            result: respData.data.result,

        };
    } catch (error) {
        return {
            status: error.response.data.status,
            message: error.response.data.message,
        };
    }
}


export const facebookLogin = async (data, dispatch) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/user/faceBookLogin`,
            data: data

        });
        // console.log(respData, "respDataresult getPlanData");
        dispatch(setAuth(true));

        return {
            loading: true,
            result: respData.data.result,
            message: respData.data.message,
            status: respData.data.status,

        };
    } catch (error) {
        return {
            status: error.response.data.status,
            message: error.response.data.message,
        };
    }
}


export const getstartupPack = async () => {
    try {
        let respData = await axios({
            method: "get",
            url: `/api/user/getstartupPack`,

        });
        // console.log(respData, "respData");
        return {
            status: respData.data.status,
            result: respData.data.result,

        };
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message,
        };
    }
};
export const startupPlanSubscribtion = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/user/startupPlanSubscribtion`,
            data
        });

        return {
            status: respData.data.status,
            message: respData.data.message,

        };
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message,
        };
    }
};


export const getTransaction = async (data) => {
    try {
        let respData = await axios({
            method: "get",
            url:
                `/api/user/transaction-history?page=` +
                data.page +
                `&limit=` +
                data.limit +
                `&search=` +
                data.search +
                `&startdate=` +
                data.startdate +
                `&enddate=` +
                data.enddate,
        });
        // console.log(respData, "respData");
        return {
            status: respData.data.success,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: err.response.data.status,
            message: err.response.data.message,
        };
    }
};
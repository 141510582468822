import React, { useEffect } from "react";
import Navbarinner from "../components/Navbarinner.js";
import Sidebar from "../components/sidebar.js";
import { useNavigate, useParams } from "react-router-dom";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";

// import components
import Withdraw from "../components/Withdraw";

//import lib
import isEmpty from "../lib/isEmpty.js";

export default function WithdrawPage() {
  const { id } = useParams();
  const history = useNavigate();
  useEffect(() => {
    if (isEmpty(id)) {
      history("/wallet");
    }
  }, [id]);

  return (
    <div className="page_wrapper">
      <ScrollToTopOnMount />
      <Navbarinner />
      <Sidebar navLink={"/wallet"} />
      <div className="innerpages_wrapper">
        <div className="container">
          <Withdraw coin={id} />
        </div>
      </div>
    </div>
  );
}

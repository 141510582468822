import React, { useEffect, useState, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTable from "react-data-table-component";
import { Modal, Dropdown, Row, Col, Form } from "react-bootstrap";
// img
import down from "../assets/images/down.svg";
import calender from "../assets/images/calender.svg";
import profit from "../assets/images/profit.svg";
import tot_order from "../assets/images/tot_order.svg";
import revenue from "../assets/images/revenue.svg";
import new_products from "../assets/images/new_products.svg";
import basket from "../assets/images/basket.svg";
import tag from "../assets/images/tag.svg";
import Sidebar from "../components/sidebar.js";
import MyChart from "../components/linechart.js";
import MyLineChart from "../components/linechart.js";
import Navbarinner from "../components/Navbarinner.js";
import { Link, useNavigate } from "react-router-dom";
import {
  Updatepayment,
  getSiteSetting,
  getdashBoardOrder,
} from "../action/user.action.js";
import { getUserStore } from "../redux/features/userstore/userStoreSlice";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../redux/features/user/userslice.js";
import moment from "moment";
import {
  ValidateEmail,
  validSpecialPositive,
  validSwift,
  ValidateUserName,
  validPositive,
} from "../helper/validation";
import { toastAlert } from "../utils/toast";

import verifyedImg from "../assets/images/68208085d424bbb26580eb7f1c4af01c5ed92e87ee99fd972f2e7e24eeee5a68-removebg-preview.png";

import balance from "../assets/images/Removal-828.png";

import { setBankDetail } from "../action/user.action";
import axios from "axios";
import { useTranslation } from "react-i18next";

let initialstate = {
  email: "",
  phone: "",
  nameonBank: "",
  bankName: "",
  accNo: "",
  ifscCode: "",
};

export default function Kyc() {
  const user = useSelector((state) => state.account);

  console.log(user, "useSelector");

  const { t } = useTranslation();

  const [formdata, setfromdata] = useState(initialstate);
  const [siteSetting, setSiteSettings] = useState();
  const [ValidateError, setValidateError] = useState({});
  const { email, phone, nameonBank, bankName, accNo, ifscCode } = formdata;

  useEffect(() => {
    fetchSiteSettings();
  }, []);

  const fetchSiteSettings = async () => {
    const { status, result } = await getSiteSetting();

    console.log(result, "result");

    if (status) {
      setSiteSettings(result);
    }
  };

  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formdata, ...{ [name]: value } };
    setfromdata(formData);
    // if (name == 'ifscCode') {
    //   try {

    //     const data = await axios.get(`https://bank-apis.justinclicks.com/API/V1/IFSC/${value}/`)

    //     console.log(data, 'ifscCode');
    //     if (data) {
    //       setfromdata({ ...formdata, ...{ ifscCode: value, bankName: data.data.BANK } })
    //     }
    //     setValidateError({})
    //   } catch (error) {
    //     setfromdata({ bankName: '' })
    //     setValidateError({ ifscCode: 'Invalid IFSC Code' })
    //   }
    // } else {

    // }
  };

  const Fromvalidation = async () => {
    try {
      var validateError = {};
      const regex = /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/;
      const regex1 =
        /^([A-Z]{2}\d{2})(\d{4}|\w{4})(\d{4}|\w{4})(\d{4}|\w{4})(\d{4}|\w{4})(\d{4}|\w{4})(\d{4}|\w{4})(\d{4}|\w{4})$/;
      // console.log(regex1.test(accNo), "46");

      if (email.trim() == "") {
        validateError.email = "EMAIL_REQUIRED";
      } else if (!ValidateEmail(email)) {
        validateError.email = "EMAIL_INVALID";
      }
      if (phone.trim() == "") {
        validateError.phone = "MOBILE_NUMBER_REQUIRED";
      }
      // if()
      if (nameonBank.trim() == "") {
        validateError.nameonBank = "NAME_REQUIRED";
      } else if (!ValidateUserName(nameonBank)) {
        validateError.nameonBank = "INVALID_NAME";
      }

      if (bankName.trim() == "") {
        validateError.bankName = "BANK_NAME_REQUIRED";
      } else if (!ValidateUserName(bankName)) {
        validateError.bankName = "INVALID_BANK_NAME";
      }

      if (accNo.trim() == "") {
        validateError.accNo = "IBAN_REQUIRED";
      } else if (!accNo.match(/^([A-Z]{2})([0-9]{2})([A-Z0-9]{9,30})$/)) {
        validateError.accNo = "INVALID_IBAN";
      }

      if (ifscCode.trim() == "") {
        validateError.ifscCode = "SWIFT_REQUIRED";
      } else if (!regex.test(ifscCode)) {
        validateError.ifscCode = "INVALID_SWIFT";
      }

      setValidateError(validateError);
      return validateError;
    } catch (err) {}
  };

  const handleSumbit = async () => {
    try {
      const check = await Fromvalidation();
      var errorsSize = Object.keys(check).length;
      if (errorsSize == 0) {
        var reqdata = {
          email,
          phone,
          nameonBank,
          bankName,
          accNo,
          ifscCode,
        };
        var { status, message } = await setBankDetail(reqdata);
        if (status) {
          toastAlert("success", t("BANK_DETAIL_SUCCESS"));
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
        // else {
        //   // var validateError = {};
        //   // validateError.oldPassword = error.oldPassword;
        //   // validateError.newPassword = error.newPassword;
        //   // validateError.conNewPassword = error.confirmNewPassword;
        //   // setValidateError(validateError);
        // }
      }
    } catch (err) {
      console.log(err);
    }
  };

  // console.log(formdata, 'formdata');

  return (
    <div className="page_wrapper">
      <Sidebar />
      <div className="w-100">
        <Navbarinner />

        {user?.bankstatus ? (
          <div className="right_wrapper">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h2 class="h3tag bdr mb-3">{t("BANK_DETAILS")}</h2>

              <div>
                <a href={siteSetting?.BankDetails_link} target="_blank">
                  <img src={balance} style={{ width: "45px" }} alt="img" />
                </a>
              </div>
            </div>
            <center>
              {user?.kycstatus == "approved" ? (
                <h3 style={{ color: "#00e0ba", fontFamily: "-webkit-body" }}>
                  <img src={verifyedImg} alt="VERIFIED SUCCESSFULLY" />
                </h3>
              ) : user?.kycstatus == "pending" ? (
                <h3>{t("BANK_PENDING")}</h3>
              ) : (
                <h3>{t("BANK_REJECTED")}</h3>
              )}
            </center>
          </div>
        ) : (
          <>
            {user?.kycstatus == "rejected" ? (
              <div className="right_wrapper">
                <h5 className="alert alert-danger">
                  {t("BANK_REJECTED")} : {user?.userBankdetails?.reason}
                </h5>
                <h2 class="h3tag bdr mb-3">{t("BANK_DETAILS")}</h2>

                <div className="row">
                  <div className="col-lg-6">
                    <div className="kyc_box">
                      <div className="input_box">
                        <label className="mb-2 sm_txt">{t("EMAIL")}</label>
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            className="form-control"
                            name="email"
                            placeholder={t("EMAIL_PLACEHOLDER")}
                            onChange={handleChange}
                          />
                        </div>
                        {ValidateError.email && (
                          <span className="text-danger">
                            {t(ValidateError.email)}
                          </span>
                        )}
                      </div>

                      <div className="input_box">
                        <label className="mb-2 sm_txt">
                          {t("MOBILE_NUMBER")}
                        </label>
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            className="form-control"
                            name="phone"
                            placeholder={t("MOBILE_PLACEHOLDER")}
                            onInput={validPositive}
                            onChange={handleChange}
                          />
                        </div>
                        {ValidateError.phone && (
                          <span className="text-danger">
                            {t(ValidateError.phone)}
                          </span>
                        )}
                      </div>

                      <div className="input_box">
                        <label className="mb-2 sm_txt">{t("NAME_BANK")}</label>
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            className="form-control"
                            name="nameonBank"
                            placeholder={t("NAME_BANK_PLACEHOLDER")}
                            onChange={handleChange}
                          />
                        </div>
                        {ValidateError.nameonBank && (
                          <span className="text-danger">
                            {t(ValidateError.nameonBank)}
                          </span>
                        )}
                      </div>

                      <div className="input_box">
                        <label className="mb-2 sm_txt">{t("IBAN")}</label>
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            className="form-control"
                            name="accNo"
                            placeholder={t("IBAN_PLACEHOLDER")}
                            onChange={handleChange}
                          />
                        </div>
                        {ValidateError.accNo && (
                          <span className="text-danger">
                            {t(ValidateError.accNo)}
                          </span>
                        )}
                      </div>

                      <div className="input_box">
                        <label className="mb-2 sm_txt">{t("SWIFT")}</label>
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            className="form-control"
                            name="ifscCode"
                            placeholder={t("SWIFT_PLACEHOLDER")}
                            onChange={handleChange}
                          />
                        </div>
                        {ValidateError.ifscCode && (
                          <span className="text-danger">
                            {t(ValidateError.ifscCode)}
                          </span>
                        )}
                      </div>
                      <div className="input_box">
                        <label className="mb-2 sm_txt">{t("BANK_NAME")}</label>
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            className="form-control"
                            name="bankName"
                            placeholder={t("BANK_PLACEHOLDER")}
                            defaultValue={bankName}
                            // disabled
                            onChange={handleChange}
                          />
                        </div>
                        {ValidateError.bankName && (
                          <span className="text-danger">
                            {t(ValidateError.bankName)}
                          </span>
                        )}
                      </div>

                      <button className="primary_btn" onClick={handleSumbit}>
                        {t("SUBMIT")}
                      </button>

                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="right_wrapper">
                <h2 class="h3tag bdr mb-3">{t("BANK_DETAILS")}</h2>

                <div className="row">
                  <div className="col-lg-6">
                    <div className="kyc_box">
                      <div className="input_box">
                        <label className="mb-2 sm_txt">{t("EMAIL")}</label>
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            className="form-control"
                            name="email"
                            placeholder={t("EMAIL_PLACEHOLDER")}
                            onChange={handleChange}
                          />
                        </div>
                        {ValidateError.email && (
                          <span className="text-danger">
                            {t(ValidateError.email)}
                          </span>
                        )}
                      </div>

                      <div className="input_box">
                        <label className="mb-2 sm_txt">
                          {t("MOBILE_NUMBER")}
                        </label>
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            className="form-control"
                            name="phone"
                            placeholder={t("MOBILE_PLACEHOLDER")}
                            onInput={validPositive}
                            onChange={handleChange}
                          />
                        </div>
                        {ValidateError.phone && (
                          <span className="text-danger">
                            {t(ValidateError.phone)}
                          </span>
                        )}
                      </div>

                      <div className="input_box">
                        <label className="mb-2 sm_txt">{t("NAME_BANK")}</label>
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            className="form-control"
                            name="nameonBank"
                            placeholder={t("NAME_BANK_PLACEHOLDER")}
                            onChange={handleChange}
                          />
                        </div>
                        {ValidateError.nameonBank && (
                          <span className="text-danger">
                            {t(ValidateError.nameonBank)}
                          </span>
                        )}
                      </div>

                      <div className="input_box">
                        <label className="mb-2 sm_txt">{t("IBAN")}</label>
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            className="form-control"
                            name="accNo"
                            placeholder={t("IBAN_PLACEHOLDER")}
                            onChange={handleChange}
                          />
                        </div>
                        {ValidateError.accNo && (
                          <span className="text-danger">
                            {t(ValidateError.accNo)}
                          </span>
                        )}
                      </div>

                      <div className="input_box">
                        <label className="mb-2 sm_txt">{t("SWIFT")}</label>
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            className="form-control"
                            name="ifscCode"
                            placeholder={t("SWIFT_PLACEHOLDER")}
                            onChange={handleChange}
                          />
                        </div>
                        {ValidateError.ifscCode && (
                          <span className="text-danger">
                            {t(ValidateError.ifscCode)}
                          </span>
                        )}
                      </div>

                      <div className="input_box">
                        <label className="mb-2 sm_txt">{t("BANK_NAME")}</label>
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={bankName}
                            name="bankName"
                            placeholder={t("BANK_PLACEHOLDER")}
                            // disabled
                            onChange={handleChange}
                          />
                        </div>
                        {ValidateError.bankName && (
                          <span className="text-danger">
                            {t(ValidateError.bankName)}
                          </span>
                        )}
                      </div>

                      {/* </div> */}
                      <button className="primary_btn" onClick={handleSumbit}>
                        {t("SUBMIT")}
                      </button>

                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

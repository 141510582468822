import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
// img
import w1 from "../assets/images/w1.svg";
import Navbarinner from "../components/Navbarinner.js";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Terms() {
  return (
    <div>
      <Navbar />
      <div className="common_page">
        <div className="container">
          <h1 className="h2tag">
            <strong>Terms</strong>
          </h1>
          <ul className="list-unstyled">
            <li>
              <strong className="mt-2 d-block">1. Introduction</strong>
            </li>
            <li>
              Welcome to AIShopping.io, a service provided by AIShopping
              Research Ltd (&ldquo;AIShopping&rdquo;, &ldquo;we&rdquo;,
              &ldquo;us&rdquo;, &ldquo;our&rdquo;). This document contains the
              terms and conditions (&ldquo;Terms&rdquo;) that govern your use of
              our product research and fulfillment service. By subscribing to
              and using our service, you (&ldquo;You&rdquo;, &ldquo;Your&rdquo;,
              &ldquo;Subscriber&rdquo;, &ldquo;User&rdquo;) agree to be bound by
              these Terms, our Privacy Policy, and any other applicable rules or
              policies that may be in place.
            </li>
            <li>
              <strong className="mt-2 d-block">2. Eligibility</strong>
            </li>
            <li>
              You affirm that you are at least 18 years of age and possess the
              legal capacity to enter into binding contracts under applicable
              laws. If you are entering into this agreement on behalf of a
              corporate entity, you represent and warrant that you have the
              legal authority to bind such corporate entity to these Terms.
            </li>
            <li>
              <strong className="mt-2 d-block">3. Scope of Service</strong>
            </li>
            <li>
              AIShopping is a platform that provides product research and
              fulfillment services to online sellers. Our offerings include a
              dashboard of features, specifically, detailed product research,
              product trends, and order fulfillment through our partners in
              China. The scope of access to our services is contingent upon the
              subscription plan you choose.
            </li>
            <li>
              <strong className="mt-2 d-block">
                4. Subscriptions, Payments, Renewals, and Subscription Refunds
              </strong>
            </li>
            <li>
              We offer a variety of subscription options: Free, Monthly, Yearly,
              and Lifetime. Only Monthly, Yearly, and Lifetime subscriptions
              grant full access to our services. Subscription fees, where
              applicable, are paid in advance and are non-refundable, except as
              expressly set forth herein. All subscriptions, unless specifically
              stated, are subject to automatic renewal at the end of their term.
              If you do not wish to have your subscription renewed, it is your
              responsibility to cancel your subscription prior to its renewal
              date.
            </li>
            <li>
              For the avoidance of doubt, we extend a 7-day money-back guarantee
              for all inaugural subscriptions. Should a User be dissatisfied
              with the service during the initial seven days subsequent to their
              first subscription, they may petition for a full refund by
              establishing contact with our customer service. This money-back
              guarantee is applicable solely to the first subscription acquired
              by a User. In the event that a User terminates their subscription
              and avails themselves of a refund under this provision, and
              subsequently re-subscribes to the service, the aforementioned
              money-back guarantee shall be deemed inapplicable to any and all
              subsequent subscriptions.
            </li>
            <li>
              The Lifetime subscription grants you full access to our service
              for the duration of the service&rsquo;s existence. The purchase of
              a Lifetime subscription does not guarantee that the service, or
              any particular feature or component of the service, will exist in
              perpetuity. The term &ldquo;Lifetime&rdquo; refers to the life of
              the service, and not the life of the subscriber or any other
              measure of lifespan. Should AIShopping decide to close,
              discontinue, modify, or otherwise alter the service, the Lifetime
              subscription does not entitle the subscriber to any refunds or
              compensation. The Lifetime subscription exempts the subscriber
              from further monthly or yearly payments, but it does not protect
              them from changes to or the discontinuation of the service.
            </li>
            <li>
              <strong className="mt-2 d-block">
                5. Account Registration and Security
              </strong>
            </li>
            <li>
              To avail of our services, you must register and maintain an active
              account. You agree to provide accurate, current, and complete
              information during the registration process, and promptly update
              such information as it changes. You are solely responsible for all
              activity that occurs under your account, and you must maintain the
              security and secrecy of your account username and password at all
              times.
            </li>
            <li>
              <strong className="mt-2 d-block">
                6. Data Privacy and UK GDPR Compliance
              </strong>
            </li>
            <li>
              AIShopping respects your privacy and will process your personal
              data in accordance with applicable data protection laws, including
              the United Kingdom General Data Protection Regulation (&ldquo;UK
              GDPR&rdquo;). Our use of your personal data is governed by our
              Privacy Policy.
            </li>
            <li>
              <strong className="mt-2 d-block">7. Third-Party Services</strong>
            </li>
            <li>
              AIShopping may incorporate third-party services to facilitate
              certain features of our platform. While we endeavour to offer
              high-quality services, we make no warranties regarding, and bear
              no liability for, the performance, content, accuracy, or security
              of third-party services.
            </li>
            <li>
              Our service may contain links to third-party websites or services
              that are not owned or controlled by AIShopping. AIShopping has no
              control over, and assumes no responsibility for, the content,
              privacy policies, or practices of any third-party websites or
              services. You further acknowledge and agree that AIShopping shall
              not be responsible or liable, directly or indirectly, for any
              damage or loss caused or alleged to be caused by or in connection
              with the use of or reliance on any such content, goods or services
              available on or through any such websites or services.
            </li>
            <li>
              <strong className="mt-2 d-block">
                8. Fulfillment by AIShopping
              </strong>
            </li>
            <li>
              Our fulfillment service involves the facilitation of product
              delivery from our partners in China directly to your end customer.
              You understand and accept that AIShopping merely acts as a
              facilitator in this process and does not exert control over the
              behaviour, performance, or content of any third-party partner.
            </li>
            <li>8.1 Fulfillment Prices and Availability</li>
            <li>
              Prices for fulfillment are subject to change in response to market
              volatility. AIShopping shall notify you of any price adjustments
              at least 24 hours before such adjustments take effect. Further,
              the availability of products for fulfillment may vary depending on
              market conditions and supply chain constraints. In the event a
              product becomes unavailable, we will promptly inform you via
              email.
            </li>
            <li>8.2 Product Damage, Loss, Defect, and Returns</li>
            <li>
              8.2.1 In circumstances of product damage or defect upon delivery,
              it is incumbent on the User to furnish AIShopping Research Ltd
              with documentary evidence of the same, preferably in the form of
              photographs, submitted via email
              to&nbsp;support@AIShopping.io&nbsp;within a period of seven (7)
              calendar days following receipt of the product.
            </li>
            <li>
              8.2.2 Similar provisions apply in instances of loss of product
              during transit, as determined by the tracking information provided
              by the responsible parcel delivery company.
            </li>
            <li>
              8.2.3 The decision as to whether a refund or a replacement product
              will be offered in such circumstances resides solely within the
              discretion of AIShopping Research Ltd. Such decisions shall be
              made on a case-by-case basis, taking into account all relevant
              circumstances surrounding the claim, including but not limited to
              the nature and extent of the damage or defect, the evidence
              provided by the User, and the tracking information available.
            </li>
            <li>8.3 Currency Conversion</li>
            <li>
              All prices pertaining to fulfillment services as presented on our
              platform are denominated in United States Dollars (USD), unless
              explicitly stated otherwise.
            </li>
            <li>
              In the event that a different currency has been selected by the
              User for the purpose of transaction, all fulfillment invoices
              shall be raised in that respective currency. For such
              transactions, the applicable exchange rate shall be determined in
              accordance with the public exchange rates promulgated by the
              European Central Bank on the date of invoice issuance.
            </li>
            <li>
              The User acknowledges that fluctuations in the applicable exchange
              rates may occur and that AIShopping accepts no liability for any
              loss or damage, direct or indirect, that the User may suffer as a
              result of such fluctuations.
            </li>
            <li>
              The User shall be solely responsible for all risks associated with
              currency conversion and agrees to indemnify AIShopping against any
              loss that it may sustain as a result of currency conversion under
              this clause.
            </li>
            <li>8.4 Fulfillment Invoicing</li>
            <li>
              Multiple orders relating to fulfillment services may be
              consolidated into a single fulfillment invoice at the sole
              discretion of AIShopping.
            </li>
            <li>
              The User is obliged to provide accurate and complete billing
              information within the dashboard for the purpose of fulfillment.
              This includes, but is not limited to, the provision of a default
              payment method, which shall be used for the execution of automatic
              payments.
            </li>
            <li>
              Fulfillment of orders shall be initiated subsequent to the receipt
              of payment, save for instances involving Users who have been
              deemed as &lsquo;trusted&rsquo; by AIShopping. The designation of
              &lsquo;trusted&rsquo; status lies solely within the purview of
              AIShopping and may be accorded or revoked at AIShopping&rsquo;s
              discretion. The fulfillment of orders prior to payment receipt for
              &lsquo;trusted&rsquo; Users does not absolve the User of the
              obligation to make due payment.
            </li>
            <li>
              The User shall receive a fulfillment invoice at the email address
              provided during the sign-up process. It is the User&rsquo;s
              responsibility to ensure that this email address is accurate and
              regularly monitored.
            </li>
            <li>
              Payment of all fulfillment invoices must be completed within a
              period of 24 hours from the issuance of the invoice. Failure to
              make timely payment may result in suspension or termination of
              access to the service, in addition to other remedies available to
              AIShopping under these Terms or applicable law.
            </li>
            <li>8.5 SEPA Mandates</li>
            <li>
              Single Euro Payments Area (SEPA) mandate is available as a method
              of payment for fulfillment services through our payment processing
              partner, Stripe. It is the User&rsquo;s responsibility to comply
              with the terms and conditions stipulated by Stripe in relation to
              the use of SEPA mandates.
            </li>
            <li>
              For each instance of a SEPA chargeback, a fee of 8.50 USD shall be
              levied against the User. This chargeback fee is subject to change
              at AIShopping&rsquo;s sole discretion and upon reasonable notice
              to the User.
            </li>
            <li>
              AIShopping Research Ltd reserves the right to demand interest on
              delayed payments in accordance with the prevailing legal
              provisions applicable within the User&rsquo;s local jurisdiction.
              This may include, but is not limited to, statutory interest rates,
              penalties, and costs associated with the recovery of such
              payments.
            </li>
            <li>
              It is the User&rsquo;s responsibility to understand the
              implications of using a SEPA mandate as a payment method,
              including any charges, penalties, and rights associated with
              chargebacks and delayed payments.
            </li>
            <li>
              <strong className="mt-2 d-block">9. Service Availability</strong>
            </li>
            <li>
              AIShopping strives to keep our services operational. However,
              certain technical difficulties, maintenance or upgrade operations
              and other factors may, from time to time, result in temporary
              service interruptions. We will work to minimise any disruption,
              inconvenience, or loss caused by such interruptions. However,
              AIShopping does not warrant that our services will be
              uninterrupted or error-free, and we will not be responsible for
              any losses or damages you may suffer as a result of service
              interruptions or losses of data.
            </li>
            <li>
              <strong className="mt-2 d-block">
                10. Changes to the Service
              </strong>
            </li>
            <li>
              AIShopping reserves the right to modify or discontinue,
              temporarily or permanently, our services (or any part thereof)
              with or without notice. This includes the right to change, add or
              remove functionalities and features of the services, or to change
              the fees and charges for our services. We will use reasonable
              efforts to provide you with notice of any significant changes to
              our services.
            </li>
            <li>
              However, it is your responsibility to check these Terms regularly
              for changes. Your continued use of the services following the
              posting of changes will mean that you accept and agree to the
              changes. If you do not wish to continue using the services under
              the new version of the Terms, you may terminate your account by
              contacting us.
            </li>
            <li>
              <strong className="mt-2 d-block">
                11. Intellectual Property Rights
              </strong>
            </li>
            <li>
              Unless otherwise indicated, all materials on our platform,
              including but not limited to text, images, logos, and trademarks,
              are owned by, or licensed to, AIShopping and are subject to
              copyright and other intellectual property rights under UK and
              foreign laws and international conventions.
            </li>
            <li>
              <strong className="mt-2 d-block">
                12. User Obligations and Conduct
              </strong>
            </li>
            <li>
              As a user of our services, you agree to act responsibly and comply
              with all applicable local, state, national, and international laws
              and regulations. You are solely responsible for all transactions
              or actions taken on your account, including any data or content
              transmitted or shared through our services.
            </li>
            <li>
              <strong className="mt-2 d-block">
                13. Limitations and Exclusions of Liability
              </strong>
            </li>
            <li>
              To the maximum extent permitted by applicable law, AIShopping, its
              directors, employees, agents, suppliers, or affiliates, shall not
              be liable for any indirect, incidental, special, consequential or
              punitive damages, or any loss of profits or revenues, whether
              incurred directly or indirectly, or any loss of data, use,
              goodwill, or other intangible losses resulting from (a) your
              access to, use of, inability to access, or inability to use our
              services; (b) any conduct or content of any third party on our
              services; (c) any content obtained from our services; and (d)
              unauthorised access, use, or alteration of your transmissions or
              content.
            </li>
            <li>
              <strong className="mt-2 d-block">
                14. Amendments to these Terms
              </strong>
            </li>
            <li>
              AIShopping reserves the right to amend these Terms at any time. If
              amendments are deemed significant, we will endeavour to provide at
              least 30 days&rsquo; notice prior to any new terms taking effect.
            </li>
            <li>
              <strong className="mt-2 d-block">15. Governing Law</strong>
            </li>
            <li>
              These Terms and any disputes arising out of or in connection with
              it will be governed by and construed in accordance with the laws
              of the United Kingdom.
            </li>
            <li>
              <strong className="mt-2 d-block">16. Termination</strong>
            </li>
            <li>
              AIShopping reserves the right to terminate or suspend your
              account, and to refuse any and all current or future use of the
              services, without prior notice or liability, for any reason or no
              reason, including but not limited to if you breach these Terms.
            </li>
            <li>
              <strong className="mt-2 d-block">17. Indemnification</strong>
            </li>
            <li>
              You agree to indemnify and hold harmless AIShopping, its
              subsidiaries, affiliates, licensors, and their respective
              officers, agents, partners, and employees, from and against any
              loss, liability, claim, demand, damages, costs, and expenses,
              including reasonable attorney&rsquo;s fees, arising out of or in
              connection with your use of our services, your conduct in
              connection with our services, or any violation of these Terms or
              of any law or the rights of any third party.
            </li>
            <li>
              <strong className="mt-2 d-block">18. Disclaimers</strong>
            </li>
            <li>
              AIShopping provides our services on an &ldquo;as is&rdquo; and
              &ldquo;as available&rdquo; basis. To the maximum extent permitted
              by law, we disclaim all warranties, express or implied, including,
              but not limited to, implied warranties of merchantability, fitness
              for a particular purpose, and non-infringement.
            </li>
            <li>
              <strong className="mt-2 d-block">19. Dispute Resolution</strong>
            </li>
            <li>
              Before resorting to formal proceedings, we strongly encourage you
              to first contact us directly to seek a resolution by going to our
              customer support site at&nbsp;support@AIShopping.io&nbsp;or at:
            </li>
            <li>
              AIShopping Research Ltd
              <br />
              71-75 Shelton Street
              <br />
              Covent Garden
              <br />
              London
              <br />
              WC2H 9JQ
              <br />
              United Kingdom
            </li>
            <li>
              <strong className="mt-2 d-block">20. Contact Information</strong>
            </li>
            <li>
              If you have any questions about these Terms, please contact us
              at&nbsp;support@AIShopping.pro.
            </li>
            <li>
              <strong className="mt-2 d-block">21. International Use</strong>
            </li>
            <li>
              Our service may be accessible worldwide, but we make no
              representation that materials on this site are appropriate or
              available for use in locations outside the United Kingdom, and
              accessing them from territories where their contents are illegal
              is prohibited.
            </li>
            <li>
              <strong className="mt-2 d-block">22. Force Majeure</strong>
            </li>
            <li>
              Neither AIShopping nor you shall be responsible for damages or for
              delays or failures in performance resulting from acts or
              occurrences beyond their reasonable control.
            </li>
            <li>
              <strong className="mt-2 d-block">23. Entire Agreement</strong>
            </li>
            <li>
              These Terms, together with any amendments and any additional
              agreements you may enter into with AIShopping in connection with
              the services, shall constitute the entire agreement between you
              and AIShopping concerning the services.
            </li>
            <li>
              <strong className="mt-2 d-block">24. Severability</strong>
            </li>
            <li>
              If any provision or part-provision of these Terms is or becomes
              invalid, illegal or unenforceable, it shall be deemed modified to
              the minimum extent necessary to make it valid, legal and
              enforceable. If such modification is not possible, the relevant
              provision or part-provision shall be deemed deleted. Any
              modification to or deletion of a provision or part-provision under
              this clause shall not affect the validity and enforceability of
              the rest of these Terms.
            </li>
            <li>
              <strong className="mt-2 d-block">25. Waiver</strong>
            </li>
            <li>
              No failure or delay by AIShopping in exercising any right or
              remedy provided under these Terms or by law shall constitute a
              waiver of that or any other right or remedy, nor shall it preclude
              or restrict the further exercise of that or any other right or
              remedy. No single or partial exercise of such right or remedy
              shall preclude or restrict the further exercise of that or any
              other right or remedy.
            </li>
            <li>
              <strong className="mt-2 d-block">26. Assignment</strong>
            </li>
            <li>
              You may not assign, delegate, or transfer these Terms or your
              rights and obligations hereunder, in any way (by operation of law
              or otherwise) without AIShopping&rsquo;s prior written consent. We
              may transfer, assign, or delegate these Terms and our rights and
              obligations without consent.
            </li>
            <li>
              <strong className="mt-2 d-block">
                27. No Partnership or Agency
              </strong>
            </li>
            <li>
              Nothing in these Terms is intended to, or shall be deemed to,
              establish any partnership or joint venture between any of the
              parties, constitute any party the agent of another party, or
              authorise any party to make or enter into any commitments for or
              on behalf of any other party.
            </li>
            <li>
              <strong className="mt-2 d-block">28. Survival</strong>
            </li>
            <li>
              All provisions of the Terms, which by their nature should survive,
              shall survive termination of services, including without
              limitation, ownership provisions, warranty disclaimers, and
              limitations of liability.
            </li>
            <li>
              <strong className="mt-2 d-block">29. Interpretation</strong>
            </li>
            <li>
              In these Terms, the words &ldquo;including&rdquo; and
              &ldquo;include&rdquo; mean &ldquo;including, but not limited
              to.&rdquo;
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
}

import React, { useEffect, useState, useRef, useMemo } from "react";
import DataTable from "react-data-table-component";
import { Scrollbars } from "react-custom-scrollbars-2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// img
import csv from "../assets/images/csv.svg";
import xlsx from "../assets/images/xlsx.svg";
import pdf from "../assets/images/pdf.svg";
import calender from "../assets/images/calender.svg";
import search_icon from "../assets/images/search_icon.svg";
import down from "../assets/images/down.svg";
import sort_arw from "../assets/images/sort_arw.svg";
import plus1 from "../assets/images/plus1.svg";

import MyChart from "../components/linechart.js";
import MyLineChart from "../components/linechart.js";
import Navbarinner from "../components/Navbarinner.js";
import { Link } from "react-router-dom";
import { getDepostlist } from "../action/user.action.js";
import moment from "moment";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import Sidebar from "../components/sidebar.js";

import {
  downloadCSV,
  downloadXls,
  downloadpdf,
} from "../helper/downloadFun.js";

import Deposits from "./deposits.js";
import Withdraw from "./withdraw.js";
import TransactionHistory from "./transactionhistory.js";
import AccountHis from "../components/finance/ActiveHistory";
import { useTranslation } from "react-i18next";

export default function Finance() {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState(1); // Initially set the first tab as active

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  return (
    <div className="page_wrapper">
      <ScrollToTopOnMount />

      <Sidebar navLink={"/finance"} />
      <div className="w-100">
        <Navbarinner />
        <div className="right_wrapper">
          <div className="make_tab">
            <div className="tabs" style={{ cursor: "pointer" }}>
              <div
                className={activeTab === 1 ? "tab active" : "tab"}
                onClick={() => handleTabClick(1)}
              >
                {t("Transaction")}
              </div>{" "}
              {/* <div className={activeTab === 1 ? "tab active" : "tab"} onClick={() => handleTabClick(1)}>
                                {t("DEPOSIT")}

                            </div>
                            <div className={activeTab === 2 ? "tab active" : "tab"} onClick={() => handleTabClick(2)}>{t("WITHDRAW")}</div> */}
              <div
                className={activeTab === 3 ? "tab active" : "tab"}
                onClick={() => handleTabClick(3)}
              >
                {t("ACCOUNT_HISTORY")}
              </div>
            </div>
          </div>

          {activeTab === 1 && (
            <div>
              {" "}
              <TransactionHistory />
            </div>
          )}
          {/* {activeTab === 1 && <div>    <Deposits /></div>}
                    {activeTab === 2 && <div>    <Withdraw />
                    </div>} */}
          {activeTab === 3 && (
            <div>
              {" "}
              <AccountHis />{" "}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

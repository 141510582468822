import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Bar, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import "chart.js/auto";
import { getChartData, getdashBoardOrder } from "../action/user.action.js";

import { useSelector } from "react-redux";
import moment from "moment";
import { toastAlert } from "../utils/toast.js";
import { useTranslation } from "react-i18next";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const formatForChart = (result, period, totalDays) => {
  try {
    let arrlen =
      period == "l10"
        ? 11
        : period == "month"
        ? 30
        : period == "year"
        ? 7
        : totalDays;

    let final = new Array(arrlen).fill(0).map((item, index) => {
      let currdate = moment().subtract(index, "days").format("YYYY-MM-DD");

      // console.log(item, "item");

      return {
        _id: currdate,
        Profit: result.find((item) => item._id == currdate) || 0,
        // ProductCos:
        //   result.totalProductCost.find((item) => item._id == currdate).count || 0,
      };
    });
    return final;
  } catch (err) {
    console.log(err);
  }
};

const setupXaxis = (setXaxis, chartData, period, dateDiff) => {
  return setXaxis({
    categories: chartData
      .map((item, index) => {
        if (period == "l10") {
          return moment(item._id).format("MMM Do");
        } else if (period == "month") {
          if (index % 3 == 0 || index + 1 == chartData.length) {
            return moment(item._id).format("MMM Do");
          } else {
            return moment(item._id).format("MMM Do");
          }
        } else if (period == "year" || period == "overall") {
          if (period == "overall" && dateDiff < 16) {
            return moment(item._id).format("MMM Do");
          }
          if (item._id.endsWith("-01")) {
            return moment(item._id).format("MMM Do");
          } else {
            return moment(item._id).format("MMM Do");
          }
        }
      })
      .reverse(),
    tooltips: chartData
      .map((item, index) => moment(item._id).format("MMM Do"))
      .reverse(),
  });
};

const MyChart = forwardRef(function MyChart(props, ref) {
  var { selecte } = props;

  const [chartdata, setchartdata] = useState([]);
  const [period, setPeriod] = useState("l10");
  const [xaxis, setXaxis] = useState({ categories: [], tooltips: [] });
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const { t } = useTranslation();

  const user = useSelector((state) => state.account);

  const dateDiff = Math.ceil(
    (new Date().getTime() - new Date(user?.createdAt).getTime()) /
      (1000 * 60 * 60 * 24)
  );

  const Getdata = useCallback(async () => {
    try {
      setchartdata([]);
      // const { status, result } = await getChartData({ period, tz: new Date() });
      const { status, result } = await getChartData({
        period,
        tz: new Date(),
        store: selecte._id || "all",
      });
      // console.log(result, "result");
      if (status) {
        let chartData = formatForChart(result, period, dateDiff);
        setupXaxis(setXaxis, chartData, period, dateDiff);
        setchartdata(chartData);
      }
    } catch (err) {
      // console.log(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period, selecte]);

  useEffect(() => {}, [chartdata, period]);

  const scaler = useCallback(
    (type) => {
      if (type == "INCREMENT") {
        switch (period) {
          case "l10":
            setPeriod("month");
            break;
          case "month":
            setPeriod("year");
            break;
          case "year":
            setPeriod("overall");
            break;
          default:
            toastAlert("info", "You've scaled out for a maximum period");
            break;
        }
      } else {
        switch (period) {
          case "l10":
            toastAlert("info", "You cannot scale down less than 10 days");
            break;
          case "month":
            setPeriod("l10");
            break;
          case "year":
            setPeriod("month");
            break;
          case "overall":
            setPeriod("year");
            break;
          default:
            setPeriod("year");
            break;
        }
      }
    },
    [period]
  );

  useImperativeHandle(ref, () => ({
    scaler,
    period,
    setPeriod,
  }));

  // const chartData = {
  //   labels: props.monthsData,
  //   datasets: [
  //     // {
  //     //   label: 'Revenue',
  //     //   data: data1, // Replace with your actual data
  //     //   backgroundColor: '#19bffb',
  //     //   borderColor: '#19bffb',
  //     //   borderWidth: 1.5,
  //     //   pointRadius: 5,
  //     //   pointHitRadius: 10,
  //     // },
  //     {
  //       label: 'Product Cost',
  //       data: props.productcosts, // Replace with your actual data
  //       backgroundColor: '#fbbb21',
  //       borderWidth: 1.5,
  //       borderColor: '#fbbb21',
  //       pointRadius: 5,
  //       pointHitRadius: 10,
  //     },
  //     {
  //       label: 'Profit',
  //       data: props.profits, // Replace with your actual data
  //       backgroundColor: '#35e3bf',
  //       borderWidth: 1.5,
  //       borderColor: '#35e3bf',
  //       pointRadius: 5,
  //       pointHitRadius: 10,
  //     },
  //   ],
  // };

  // const chartOptions = {

  //   layout: {
  //     padding: {
  //       // top: -10, // Set the top padding value (adjust as needed)
  //     },
  //   },
  //   maintainAspectRatio: false,
  //   scales: {
  //     yAxes: [
  //       {
  //         ticks: {
  //           beginAtZero: true,
  //         },
  //       },
  //     ],
  //   },
  //   plugins: {
  //     legend: {
  //       align: "end",
  //       display: false,
  //       labels: {
  //         usePointStyle: true,
  //         pointStyle: 'circle',
  //         // pointStyleWidth: 10,
  //         maxHeight: 105,
  //         // padding:20,
  //       },
  //     },
  //   }

  // };

  useEffect(() => {
    Getdata();
  }, [Getdata]);

  const options1 = {
    responsive: true,

    plugins: {
      legend: {
        position: "top",
        onClick: (evt, legendItem, legend) => {},
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            let label = "";
            if (context.parsed.y) {
              label = context.parsed.y + " " + "$";
            }
            return label;
          },
        },
      },
      title: {
        display: true,
        text: "Chart.js Line Chart",
      },
    },
    scales: {
      x: {
        grid: {
          display: true,
        },
      },
      y: {
        grid: {
          display: true,
        },
      },
    },
  };

  // const options = {
  //   responsive: true,
  //   plugins: {
  //     legend: {
  //       position: 'top',
  //     },
  //     title: {
  //       display: true,
  //       // text: 'Chart.js Line Chart',
  //     },
  //   },
  // };

  const options = {
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        display: true,
        // text: 'Chart.js Line Chart - Multi Axis',
      },
    },
    // scales: {
    //   y: {
    //     type: 'linear' ,
    //     display: true,
    //     position: 'left' ,
    //   },
    //   y1: {
    //     type: 'linear' ,
    //     display: true,
    //     position: 'right',
    //     grid: {
    //       drawOnChartArea: false,
    //     },
    //   },
    // },
  };

  const createGradient = (context, color, stop) => {
    if (!context?.chart?.chartArea) return;
    const {
      ctx,
      data,
      chartArea: { top, bottom },
    } = context.chart;
    const gradient = ctx.createLinearGradient(0, top, 0, bottom);
    gradient.addColorStop(1, color);
    gradient.addColorStop(1, stop);

    return gradient;
  };

  const pointHelper = (context) => {
    return context?.raw || 0 > 0 ? 3 : 0;
  };

  const chartData = {
    labels: xaxis.categories,
    datasets: [
      {
        fill: true,
        label: t("PROFIT"),
        data: chartdata
          ?.map((item) => item?.Profit?.totalProjit || 0)
          .reverse(),
        // borderColor: "rgba(43, 152, 215, 1)",
        // backgroundColor: (context) =>
        //   createGradient(
        //     context,
        //     "rgba(53, 155, 227,1)",
        //     "rgba(255, 255, 255, 0)",
        //   ),

        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",

        cubicInterpolationMode: "monotone",
        pointRadius: pointHelper,
      },
      {
        fill: true,
        label: t("PRODUCT_COST"),
        data: chartdata
          ?.map((item) => item?.Profit?.totalProductCost || 0)
          .reverse(),
        // borderColor: "rgb(235, 188, 62)",
        // backgroundColor: (context) =>
        //   createGradient(
        //     context,
        //     "rgba(243, 218, 95, 1)",
        //     "rgba(255, 255, 255, 0)",
        //   ),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        cubicInterpolationMode: "monotone",
        pointRadius: pointHelper,
      },
    ],
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    return () => {
      // Remove resize event listener on cleanup
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Line
        data={chartData}
        options={options}
        height={screenWidth < 575 ? 300 : "120px"}
      />
    </>
  );
});
export default MyChart;

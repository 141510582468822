// import config
import { getUserWallet } from "../redux/features/wallet/walletslice";
import axios from "./axios";

// import constant
// import {
//     SET_USER_WALLET_LIST,SET_CURRENCY,SET_PRICE_CONVERSION, SET_AVAILABLE_BALANCE,
//     // SET_USER_FIRST_CURRENCY,
//     // SET_USER_SECOND_CURRENCY,
//     // UPDATE_USER_WALLET_STAKE
// } from '../constant';

export const getAssetData = async (dispatch) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/user/getAssetsDetails`,
        });
        // dispatch(userWalletList(respData?.data?.result?.assets))
        return {
            loading: true,
            result: respData.data.result,
            message: respData.data.message,
            status: respData.data.status,
        }
    }
    catch (err) {
        // handleResp(err, 'error')
        return false
    }
}

export const userWalletList = (data) => {
    // console.log(data,'datadatadata')
    // return data
    // type: SET_USER_WALLET_LIST,
}

export const transferCurrency = async (data, dispatch) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/user/transfer`,
            'data': data
        });
        dispatch(getUserWallet());
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            result: respData.data.result
        }
    }
    catch (err) {
        // handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}

export const WithdrawReq = async (data, dispatch) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/user/withdrawrequest`,
            'data': data
        });
        dispatch(getUserWallet());
        if (respData?.data?.status === "OTP") {
            return {
                status: "OTP",
                loading: false,
                message: respData.data.message,
            }
        }
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        }
    }
    catch (err) {
        // handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            errors: err.response.data.errors,
            il8: err.response.data.il8,
        }
    }
}


export const FiatWithdrawReq = async (data, dispatch) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/user/fiatwithdraw`,
            'data': data
        });
        dispatch(getUserWallet());
        if (respData?.data?.status === "OTP") {
            return {
                status: "OTP",
                loading: false,
                message: respData.data.message,
            }
        }
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
        }
    }
    catch (err) {
        // handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            errors: err.response.data.errors,
            il8: err.response.data.il8,
        }
    }
}

// export const getTrnxHistory = async (params) => {
//     try {
//         let respData = await axios({
//             'method': 'get',
//             'url': `/api/user/transaction-history`,
//             params
//         });

//         return {
//             status: "success",
//             loading: false,
//             result: respData.data.result
//         }
//     }
//     catch (err) {
//         handleResp(err, 'error')
//         return {
//             status: "failed",
//             loading: false,
//         }
//     }
// }




export const getCurrency = async (dispatch) => {
    try {
        let respData = await axios({
            method: "get",
            url: `/api/user/getCurrency`,
        });
        //   console.log(respData.data.result,'datadatadata')
        return {
            loading: true,
            result: respData.data.result,
            message: respData.data.message,
            status: respData.data.status,
        }

        //   dispatch(setCurrencyOption(respData.data.result));
        //   return {
        //     status: "success",
        //     loading: false,
        //     result: respData.data.result,
        //   };
    } catch (err) {
        //   handleResp(err, "error");
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            errors: err.response.data.errors,
        }
    }
};


//   export const setCurrencyOption = (data) => {
//     return {
//       type: SET_CURRENCY,
//       data,
//     };
//   };

export const getPriceConversion = async (dispatch) => {
    try {
        let respData = await axios({
            method: "get",
            url: `/api/user/priceConversion`,
        });
        return {
            loading: true,
            result: respData.data.result,
            message: respData.data.message,
            status: respData.data.status,
        }
        //   dispatch({
        //     type: SET_PRICE_CONVERSION,
        //     data: respData.data.result,
        //   });
        //   return true;
    } catch (err) {
        // handleResp(err, "error");
        return false;
    }
};


export const DepositRequest = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/user/depositrequest`,
            'data': data
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            result: respData.data.result
        }
    }
    catch (err) {
        // handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            errors: err.response.data.errors,
            il8: err.response.data.il8,
        }
    }
}

import { getCurrency } from "../../../action/walletAction";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = [];

export const getCurrencyList = createAsyncThunk("currency", async () => {
  // console.log('rrrrr');
  const { status, result } = await getCurrency();
  // console.log(status,result, 'statusstatusstatus')

  if (result) return result;
  else return initialState;
});

const todoSlice = createSlice({
  name: "currency",
  initialState,
  reducers: {
    accountReset: (state, action) => {
      state = initialState;
      return state;
    },
    deleteTodo: (state, action) => {
      state.tasks = state.tasks.filter((task) => task.id !== action.payload);
    },
  },
  extraReducers: (build) => {
    build.addCase(getCurrencyList.fulfilled, (state, action) => {
      // console.log(state, 'statestatestate')

      state = action.payload;
      return state;
    });
  },
});

export const { deleteTodo, accountReset } = todoSlice.actions;

export default todoSlice.reducer;

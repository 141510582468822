import React, { useEffect, useState } from "react";
import left_arw from "../../assets/images/left_arw.svg";
import DataTable from "react-data-table-component";
import { Scrollbars } from "react-custom-scrollbars-2";
import { Modal, Dropdown, Nav, Tab } from "react-bootstrap";
import { getMyDraft } from "../../action/user.action";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { deleteDraft } from "../../action/user.action";
import { toastAlert } from "../../utils/toast";
import { useTranslation } from "react-i18next";

import { bulkImportProduct } from "../../action/user.action";
import ImportProductPop from "../importProduct/ImportProductPop";

import { getUserProfile } from "../../redux/features/user/userslice";
import { getUserDraft } from "../../redux/features/draft/mydraftslice";
import { getMyproduct } from "../../redux/features/getmyProduct/myProductSlice";
import { validPositive } from "../../helper/validation";
import { getUserWallet } from "../../redux/features/wallet/walletslice";

function DraftPop(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [show, setshow] = useState(false);
  const [show4, setshow4] = useState(false);

  const [productInfo, setproductInfo] = useState([]);
  const [show3, setshow3] = useState(false);
  const [draftId, setdraftId] = useState("");
  const [bulkimport, setbulkimport] = useState(false);
  const [selldata, setselldata] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);

  var { show2, handleClose2 } = props;

  const userdraft = useSelector((state) => state?.userdraft);
  const myproduct = useSelector((state) => state?.myproduct);
  const userstore = useSelector((state) => state?.userstore);

  // console.log(userstore, userdraft, myproduct, 'sakthibala');

  const HandleDelete = async (id) => {
    try {
      var { status, message } = await deleteDraft({ draftid: id });
      if (status) {
        setshow3(false);
        dispatch(getUserDraft());
        toastAlert("success", t(message));
      } else {
        toastAlert("error", t(message));
      }
    } catch (err) {}
  };

  const handleopen = (id) => {
    setshow3(true);
    setdraftId(id);
  };

  const handleOpen = (data) => {
    try {
      setproductInfo(data);
      setshow(true);
    } catch (err) {}
  };

  const handleClose = () => {
    setshow(false);
  };

  const handleBulkChanges = (e, draftid) => {
    var { value, name } = e.target;

    var foundIndex = selldata.findIndex((x) => x.draftid == draftid);

    if (foundIndex != -1) {
      let newArr = [...selldata];
      newArr[foundIndex].sellprice = value;
      setselldata(newArr);
    } else {
      setselldata((state) => [
        ...state,
        { sellprice: value, product_id: name, draftid: draftid },
      ]);
    }
  };

  const handleBulkImport = async () => {
    try {
      if (selldata.length == 0) {
        toastAlert("error", t("SELLING_PRICE_ERROR"));
        return false;
      }

      if (selectedValue == null) {
        toastAlert("error", t("SELECT_STORE_ERROR"));
        return false;
      }

      const filteredData = selldata.filter(
        (selldata) => selldata.sellprice !== "" && selldata.sellprice != 0
      );

      var reqdata = {
        importdata: filteredData,
        store_id: selectedValue?._id,
      };

      var { status, message, il8 } = await bulkImportProduct(reqdata);

      if (status) {
        dispatch(getUserProfile());
        dispatch(getUserDraft());
        dispatch(getUserWallet());
        dispatch(getMyproduct());
        setselldata([]);

        setshow4(false);
        setbulkimport(false);
        toastAlert("success", t(message, il8));
      } else {
        toastAlert("error", t(message, il8));
      }
    } catch (err) {
      console.log("ERROR", err);
    }
  };

  const columns = [
    {
      name: t("PRODUCT"),
      selector: (row) => row.product_title,
      width: "210px",
      cell: (row) => <div className="sem">{row.product_title}</div>,
    },
    {
      name: t("CREATED"),
      selector: (row) => row.createdAt,
      width: "170px",
      cell: (row) => (
        <div className="sem">{moment(row.createdAt).format("lll")}</div>
      ),
    },
    // {
    //     name: 'Source Item ID',
    //     selector: row => row.SourceItemID,
    // },
    // {
    //     name: 'Sell Item ID',
    //     selector: row => row.SellItemID,
    // },
    {
      name: t("ACTUAL_PRICE"),
      width: "120px",
      selector: (row) => row.product_cost,
      cell: (row) => <div className="sem">${row.product_cost}</div>,
    },
    {
      name: t("IMPORT_PRICE"),
      label: "sdf",
      width: "150px",

      selector: (row) => row.import_cost,
      cell: (row) => <div className="sem">${row.import_cost}</div>,
    },
    {
      name: t("AVERAGE_MARKPRICE"),
      width: "180px",
      // selector: (row) => row.import_cost,
      cell: (row) => (
        <div className="sem">
          $
          {(
            Number(row.product_cost) +
            (Number(row.product_cost) * 30) / 100
          ).toFixed(2)}
        </div>
      ),
    },
    // {

    //     name: 'Store',
    //     selector: row => row.Store,
    // },
    {
      name: t("STATUS"),
      selector: (row) => row.status,
      cell: (row) => <div className="sem">{row.status}</div>,
    },
    {
      name: t("ACTION"),
      width: "200px",
      cell: (row) => (
        <div class="add">
          {userstore.length > 0 ? (
            <button class="primary_btn" onClick={() => handleOpen(row)}>
              {" "}
              + {t("ADD_STORE")}
            </button>
          ) : (
            <div className="sem">{t("CREATE_STORE")} </div>
          )}
        </div>
      ),
    },
    {
      cell: (row) => (
        <div class="add">
          <button class="primary_btn" onClick={() => handleopen(row._id)}>
            {" "}
            <i class="fa fa-trash" aria-hidden="true"></i>
          </button>
        </div>
      ),
    },
  ];

  const columns2 = [
    {
      name: t("PRODUCT"),
      selector: (row) => row.product_title,
      width: "210px",
      cell: (row) => <div className="sem">{row.product_title}</div>,
    },
    {
      name: t("CREATED"),
      selector: (row) => row.createdAt,
      width: "170px",
      cell: (row) => (
        <div className="sem">{moment(row.createdAt).format("lll")}</div>
      ),
    },
    // {
    //     name: 'Source Item ID',
    //     selector: row => row.SourceItemID,
    // },
    // {
    //     name: 'Sell Item ID',
    //     selector: row => row.SellItemID,
    // },
    {
      name: t("ACTUAL_PRICE"),
      width: "120px",
      selector: (row) => row.product_cost,
      cell: (row) => <div className="sem">${row.product_cost}</div>,
    },
    {
      name: t("IMPORT_PRICE"),
      width: "150px",
      selector: (row) => row.import_cost,
      cell: (row) => <div className="sem">${row.import_cost}</div>,
    },
    {
      name: t("AVERAGE_MARKPRICE"),
      width: "180px",
      // selector: (row) => row.import_cost,
      cell: (row) => (
        <div className="sem">
          $
          {(
            Number(row.product_cost) +
            (Number(row.product_cost) * 30) / 100
          ).toFixed(2)}
        </div>
      ),
    },
    {
      name: t("STATUS"),
      selector: (row) => row.status,
      cell: (row) => <div className="sem">{row.status}</div>,
    },
    {
      name: t("SELLING_PRICE"),
      width: "150px",
      selector: (row) => row.status,
      cell: (row) => (
        <div class="input-group mb-3">
          <input
            type="text"
            className="form-control mt-4"
            placeholder={t("SELL_PRICE")}
            name={row.product_id}
            onInput={validPositive}
            onChange={(e) => handleBulkChanges(e, row._id)}
          ></input>
        </div>
      ),
    },
    {
      name: t("ACTION"),
      width: "200px",
      cell: (row) => (
        <div class="add">
          <button class="primary_btn" onClick={() => handleopen(row._id)}>
            {" "}
            <i class="fa fa-trash" aria-hidden="true"></i>
          </button>
        </div>
      ),
    },
  ];

  const columns1 = [
    {
      name: t("PRODUCT"),
      selector: (row) => row.product_title,
      width: "210px",
      cell: (row) => <div className="sem">{row.product_title}</div>,
    },
    {
      name: t("CREATED"),
      selector: (row) => row.createdAt,
      width: "170px",
      cell: (row) => (
        <div className="sem">{moment(row.createdAt).format("lll")}</div>
      ),
    },
    {
      name: t("PRODUCT_ID"),
      width: "240px",
      selector: (row) => row.product_id,
    },
    // {
    //     name: 'Sell Item ID',
    //     selector: row => row.SellItemID,
    // },

    {
      name: t("SELL_PRICE"),
      width: "150px",
      selector: (row) => row.sell_cost,
      cell: (row) => <div className="sem">${row.sell_cost}</div>,
    },
    {
      name: t("IMPORT_PRICE"),
      width: "150px",
      selector: (row) => row.import_cost,
      cell: (row) => <div className="sem">${row.import_cost}</div>,
    },
    {
      name: t("AVERAGE_MARKPRICE"),
      width: "180px",
      // selector: (row) => row.import_cost,
      cell: (row) => (
        <div className="sem">
          {/* {console.log(row ,'pppppppppppppppppppppp')} */}$
          {(
            Number(row.actual_cost) +
            (Number(row.actual_cost) * 30) / 100
          ).toFixed(2)}
        </div>
      ),
    },
    {
      name: t("REASON"),
      width: "180px",
      selector: (row) => row.reason,
      cell: (row) => (
        <div className="sem">
          {row.reason == "insufficient funds"
            ? t("INSUFFICIENT_FUNDS")
            : row.reason || "-"}
        </div>
      ),
    },

    {
      name: t("STATUS"),
      width: "200px",
      selector: (row) => row.status,
      cell: (row) => (
        <div className="sem">
          {row.status == "completed"
            ? t("COMPLETED")
            : row.status == "pending"
            ? t("PENDING")
            : t("FAILED")}
        </div>
      ),
    },
  ];

  const CustomNoDataMessage = () => (
    <div className="text-center py-5 my-5">
      <p>{t("NO_DATA")}</p>
    </div>
  );

  const handleCheckbox = (e) => {
    if (e.target.value == "true") {
      setbulkimport(false);
    } else {
      setbulkimport(true);
    }
  };

  const handleSelect = (selectedItem) => {
    setSelectedValue(JSON.parse(selectedItem));
  };

  const handleClose4 = () => {
    setshow4(false);
  };

  const customPaginationOptions = {
    rowsPerPageText: t("ROWS_PER_PAGE"),
  };

  return (
    <>
      <Modal
        show={show2}
        centered
        size="xl"
        className="custom_modal"
        onHide={handleClose2}
      >
        <Modal.Header className="mb-3" closeButton>
          <div className="cursor-pointer">
            <img src={left_arw} className="img-fluid me-2 mb-1" alt="logo" />
            {t("BACK")}
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="import_box">
            <h6>{t("DRAFT_PRODUCT")}</h6>
            <div className="custom_tab">
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Nav>
                  <Nav.Item>
                    <Nav.Link eventKey="first">
                      {t("ALL_PRODUCT")} ({userdraft.length}){" "}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second">
                      {t("FAILED")} ({myproduct?.failed?.length}){" "}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="third">
                      {t("SUCCESSFUL")} ({myproduct?.completed?.length})
                    </Nav.Link>
                  </Nav.Item>
                </Nav>

                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <Scrollbars style={{ width: "100%", height: 400 }}>
                      <div className="custom_table_chg onetime">
                        {userdraft.length > 0 ? (
                          <>
                            <>
                              {t("ACTIONS")} : &nbsp;
                              {userstore.length == 0 ? (
                                t("CREATE_STORE")
                              ) : (
                                <input
                                  type="checkbox"
                                  value={bulkimport}
                                  checked={bulkimport}
                                  onClick={handleCheckbox}
                                />
                              )}
                            </>
                            &nbsp; &nbsp; &nbsp; &nbsp;
                            {bulkimport ? (
                              <>
                                <button
                                  class="primary_btn"
                                  onClick={() => setshow4(true)}
                                >
                                  {t("IMPORT")}
                                </button>
                              </>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          ""
                        )}

                        <DataTable
                          columns={bulkimport ? columns2 : columns}
                          data={userdraft}
                          noDataComponent={<CustomNoDataMessage />}
                          paginationComponentOptions={customPaginationOptions}
                          // pagination
                          // paginationPerPage={5}
                          // paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        />
                      </div>
                    </Scrollbars>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <Scrollbars style={{ width: "100%", height: 400 }}>
                      <div className="custom_table_chg onetime1">
                        <DataTable
                          columns={columns1}
                          data={myproduct?.failed}
                          noDataComponent={<CustomNoDataMessage />}
                          pagination
                          paginationPerPage={5}
                          paginationRowsPerPageOptions={[5, 10, 15, 20]}
                          paginationComponentOptions={customPaginationOptions}
                        />
                      </div>
                    </Scrollbars>
                  </Tab.Pane>
                  <Tab.Pane eventKey="third">
                    <Scrollbars style={{ width: "100%", height: 400 }}>
                      <div className="custom_table_chg onetime1">
                        <DataTable
                          columns={columns1}
                          data={myproduct?.completed}
                          noDataComponent={<CustomNoDataMessage />}
                          pagination
                          paginationPerPage={5}
                          paginationRowsPerPageOptions={[5, 10, 15, 20]}
                          paginationComponentOptions={customPaginationOptions}
                        />
                      </div>
                    </Scrollbars>
                  </Tab.Pane>
                  <Tab.Pane eventKey="fourth">{t("FOURTH_TAB")}</Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
          {/* <div class="add"><button class="primary_btn"> +  Add Store</button></div> */}
        </Modal.Body>
      </Modal>

      <Modal show={show3} size="sm" className="custom_modal">
        <Modal.Header className="mb-3">
          <div className="cursor-pointer">
            <h6>{t("ARE_YOU_REMOVE")}?</h6>
          </div>
        </Modal.Header>
        <Modal.Body>
          <button
            class="secondary_btn"
            style={{ backgound: "none !important" }}
            onClick={() => setshow3(false)}
          >
            {" "}
            {t("CANCEL")}
          </button>
          &nbsp; &nbsp;
          <button class="primary_btn" onClick={() => HandleDelete(draftId)}>
            {" "}
            {t("CONFIRM")}
          </button>
        </Modal.Body>
      </Modal>

      <Modal
        show={show4}
        centered
        size="sm"
        className="custom_modal"
        onHide={handleClose4}
      >
        <Modal.Header className="mb-3" closeButton>
          <div className="cursor-pointer">
            <h6>{t("SELECT_YOUR_STORE")}</h6>
          </div>
        </Modal.Header>
        <Modal.Body>
          <label className="sem sm_txt mb-3">{t("SELECT_STORE")}</label>
          <div className="drp_dwn drp_dwn_hgt mb-4">
            <Dropdown onSelect={handleSelect}>
              <Dropdown.Toggle className="d-flex justify-content-between">
                {selectedValue
                  ? ` ${selectedValue?.store_name}`
                  : t("SELECT_STORE")}
              </Dropdown.Toggle>

              <Dropdown.Menu className="w-100">
                {userstore.length > 0 &&
                  userstore?.map((item) => {
                    if (item?.status == "active")
                      return (
                        <Dropdown.Item eventKey={JSON.stringify(item)}>
                          {item?.store_name +
                            "-" +
                            item?.default_storeid?.Store_name}
                        </Dropdown.Item>
                      );
                  })}
              </Dropdown.Menu>
            </Dropdown>
            {/* {validation.store && (
                            <span className="text-danger">
                                {validation.store}
                            </span>
                        )} */}
          </div>
          <button
            class="primary_btn"
            style={{ backgound: "none !important" }}
            onClick={handleBulkImport}
          >
            {" "}
            {t("CONFIRM")}
          </button>
        </Modal.Body>
      </Modal>

      <ImportProductPop
        show={show}
        setshow={setshow}
        handleClose={handleClose}
        productInfo={productInfo}
      />
    </>
  );
}

export default DraftPop;

import { combineReducers } from "redux";
import auth from "./features/auth/authslice";
import wallet from "./features/wallet/walletslice";
import account from "./features/user/userslice"
import defaultstore from "./features/defaultstore/defaultStoreSlice"
import userstore from "./features/userstore/userStoreSlice"
import userdraft from "./features/draft/mydraftslice"
import myproduct from "./features/getmyProduct/myProductSlice"
import notify from "./features/notification/notificationSlice"
import priceconversion from "./features/priceconversion/priceslice"
import currency from "./features/currency/currencyslice";

const mainReducer = (asyncReducers) => (state, action) => {
    const combinedReducers = combineReducers({
        auth,
        wallet,
        currency,
        priceconversion,
        account,
        defaultstore,
        userstore, userdraft, myproduct, notify,
        ...asyncReducers,
    });

    return combinedReducers(state, action);
};

export default mainReducer;